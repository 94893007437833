import Swal from "sweetalert2";

export const customSwaltAlert = (props = {}) => {

    const {
        icon = 'warning',
        title = '',
        titleColor = '#01A0F6',
        textColor = '#6E6F7C',
        text = '',
        showCancelButton = true,
        confirmButtonColor = "#01A0F6",
        cancelButtonColor = "#005DBF",
        confirmButtonText = `${props.isPreview ? 'Previsualizar' : (!props.showCancelButton ? 'Aceptar' : 'Si, continuar')}`, 
        cancelButtonText = "Cancelar",
        timer,
        footer,
        showDenyButton = false,
        denyButtonText = `Denegar`,
        denyButtonColor= `rgba(255, 139, 0, 3)`,
        allowOutsideClick= true,
        allowEscapeKey= true,
        customClass = {}
    } = props;

    const mergedCustomClass = {
        confirmButton: customClass.confirmButton || 'ordBtnPrimaryModuleAdmin',
        cancelButton: customClass.cancelButton || 'ordBtnSecondaryModuleAdmin',
        denyButton: customClass.denyButton || 'ordBtnDenyModuleAdmin'
    };

    return Swal.fire({
        title: `<span style=color:${titleColor}>${title}</span>`,
        html: `<span style=color:${textColor}>${text}</span>`,
        icon: icon,
        timer,
        footer,
        showCancelButton: showCancelButton,
        confirmButtonColor: confirmButtonColor,
        cancelButtonColor: cancelButtonColor,
        confirmButtonText: confirmButtonText,
        cancelButtonText: cancelButtonText,
        showDenyButton: showDenyButton,
        denyButtonText: denyButtonText,
        denyButtonColor: denyButtonColor,
        allowEscapeKey: allowEscapeKey,
        allowOutsideClick: allowOutsideClick,
        customClass: mergedCustomClass
    });
}
