import { ON_CHECK_CONTRACT_STATUS, ON_CHECK_CONTRACT_STATUS_CLEAR } from "../actions/actionTypes";

const initialState = {
    status: false,
    contractsAccounts: []
};
export const checkContractStatusReducer = (state = initialState, action) => {
    switch (action.type) {
        case ON_CHECK_CONTRACT_STATUS:
            return {
                ...state,
                status: action.status,
                contractsAccounts: action.contractsAccounts
            };
        case ON_CHECK_CONTRACT_STATUS_CLEAR:
            return {
                ...initialState
            };
        default:
            return state;
    }
};