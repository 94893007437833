import { Tooltip } from '@material-ui/core';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Col, Nav, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import {
  BodyContent,
  Container,
  Description,
  Section,
  Timeline,
  YearContent,
} from 'vertical-timeline-reactjs';

import Ellipse from '../../../assets/img/icons/Ellipse.svg';
import anonymus from '../../../assets/img/icons/anonymus.svg';
import circleGreenCheck from '../../../assets/img/icons/circleGreenCheck.svg';
import grayPen from '../../../assets/img/icons/grayPen.svg';
import backArrow from '../../../assets/img/icons/iconBack.svg';
import printer from '../../../assets/img/icons/iconPrint.svg';
import link from '../../../assets/img/icons/link.svg';

import { getCandidateInfo, getUrlContract } from '../../../actions/partnersActions';

import { getCurriculmPdf } from '../../../actions/myPayrollActions';
import {
  convertMoneyFormat,
  isEmptyOrUndefined,
  message,
  placeMyIcon,
  today,
  formatteDocument
} from '../../../helpers';

import ModalNew from '../../../components/Layouts/ModalNew';
import { PdfViewer } from '../../Layouts/PdfViewer';
import CurriculumEndowments from '../../PayRollModule/partners/CurriculumEndowments';
import { DevAndMotivation } from './DevAndMotivation';
import { DiscountsAndPayout } from './DiscountsAndPayout';
import { Examns } from './Examns';

import 'reactjs-popup/dist/index.css';
import IndividualStyles from '../../../components/TableIndividualAuction/IndividualAuction_CCS.module.scss';
import tableStyles from '../../Layouts/tableStyle.module.scss';
import '../../TableUsers/tableUserStyle.scss';
import { useHasPermissionByTagModule } from "../../../Hooks";

export const CurriculumDetail = () => {
  const store = useSelector(state => state);
  const location = useLocation();

  let currentPercentage = location?.state?.percentage;
  let id_candidate = location.state?.id_candidate;
  let id_contract = location.state?.id_contract;
  const token = store.loginReducer.Authorization;
  const employeeInfo = store.partnersReducer?.employee_info;
  let arrSections = employeeInfo?.sections;

  const myPermission = store.loginReducer.currentAccount?.profile?.permission?.find(
    x => x.functionality?.prefix === 'pplTm',
  );
  const [showPdf, setShowPdf] = useState(false);
  const [showContract, setShowContract] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const [base64, setBase64] = useState('');

  const getPdf = async () => {
    setShowPdf(true);
    const result = await getCurriculmPdf(
      {
        id_candidate: id_candidate,
        id_contract: id_contract,
        generalInfo: 1,
      },
      token,
    );
    if (result?.success) {
      return setBase64(result?.key?.split("'")[1]);
    } else {
      message(
        'error',
        'Ha ocurrido un error',
        'No ha sido posible cargar el documento',
        undefined,
        true,
      );
      return setShowPdf(false);
    }
  };
  const [slider, setSlider] = useState({
    infoGen: true,
    attached: false,
    discounts: false,
    devAndMove: false,
    endowment: false,
    examns: false,
  });
  useEffect(() => {
    if (slider.infoGen) {
      dispatch(
        getCandidateInfo({ id_candidate, id_contract, generalInfo: 1 }, res => {
          if (res?.results?.education?.length > 0) {
            const a = document.getElementsByClassName('boxEducation');
            Array.from(a).forEach(element => {
              if (
                element?.children[0]?.children[0]?.children[0]?.children[0]?.children[0]
                  ?.innerHTML !== undefined
              ) {
                element.children[0].children[0].children[0].children[0].children[0].innerHTML =
                  'Fecha de graduación';
              }
            });
          }
        }),
      );
    } else if (slider.attached) {
      dispatch(
        getCandidateInfo({ id_candidate, id_contract }, res => {
          if (res?.results?.education?.length > 0) {
            const a = document.getElementsByClassName('boxEducation');
            Array.from(a).forEach(element => {
              if (
                element?.children[0]?.children[0]?.children[0]?.children[0]?.children[0]
                  ?.innerHTML !== undefined
              ) {
                element.children[0].children[0].children[0].children[0].children[0].innerHTML =
                  'Fecha de graduación';
              }
            });
          }
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slider]);
  /* ---------------------------- GET CONTRACT  URL --------------------------- */

  useHasPermissionByTagModule({hasRead: myPermission?.read, module: 'nomina'})

  useEffect(() => {
    dispatch(getUrlContract(id_contract));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleBack = () => {
    history.goBack();
  };

  const [showDoc, setShowDoc] = useState({
    show: false,
    url: '',
  });

  const renderSections = () => {
    let sections = [];
    if (Array.isArray(arrSections)) {
      arrSections.forEach((elem, index) => {
        let docs = [];
        if (Array.isArray(employeeInfo?.documents)) {
          const filteredDocs = employeeInfo?.documents.filter(doc => doc.section === elem.section);
          filteredDocs.forEach(elem2 => {
            let filename = elem2?.s3_location?.split('/').pop();
            let ext = filename?.split('.')[1];

            const generaterow = (
              <div key={elem2.id}>
                <div>
                  <div>
                    <label
                      htmlFor={`checkBox-${elem2.id}`}
                      className={`${tableStyles.darkBlueText} ${tableStyles.f13}`}
                      style={{ marginLeft: '13%' }}
                    >
                      {elem2.doc_description}
                    </label>
                  </div>
                  {isEmptyOrUndefined(elem2.s3_location) ? (
                    <Row>
                      <Col xs={12} className='d-flex'>
                        <img className='mr-2' src={Ellipse} alt=''></img>
                        <div className={`w-100 ${tableStyles.clearBlueBorder}`}>
                          <input
                            readOnly={true}
                            className={` register-inputs ${tableStyles.clearBlueText}
                            ${IndividualStyles.ellipsis} ${tableStyles.noBorderInput} ${tableStyles.noBorderFocus} `}
                          />
                        </div>
                      </Col>
                    </Row>
                  ) : (
                    <Row>
                      <Col xs={12} className='d-flex'>
                        <img className='mr-2' src={circleGreenCheck} alt=''></img>

                        <div className={`w-100 ${tableStyles.clearBlueBorder}`}>
                          <img src={placeMyIcon(ext)} alt=''></img>
                          <input
                            readOnly={true}
                            style={{
                              backgroundColor: '#eff4fb',
                              textDecoration: 'underline',
                              color: '#005dbf',
                            }}
                            className={`register-inputs ${tableStyles.clearBlueText} hoverPointer
                           ${IndividualStyles.ellipsis} ${tableStyles.noBorderInput} `}
                            onClick={
                              isEmptyOrUndefined(elem2.s3_location)
                                ? null
                                : () =>
                                    setShowDoc({
                                      show: true,
                                      url: elem2.s3_location,
                                    })
                            }
                            value={
                              isEmptyOrUndefined(elem2.s3_location)
                                ? null
                                : elem2.s3_location.split('/').pop()
                            }
                          />
                        </div>
                      </Col>
                    </Row>
                  )}
                </div>
              </div>
            );
            if (elem2.doc_description) {
              docs.push(generaterow);
            }
          });
        }
        const generateSec = (
          <div key={index} className='mt-4'>
            <div style={{ width: '21.5rem' }}>
              <b className={`${tableStyles.darkBlueText}`}>{elem.section}</b>

              {docs}
            </div>
          </div>
        );
        sections.push(generateSec);
      });
    }
    return (
      <div key={'sectionwrap'} className={'mb-4 d-flex justify-content-between'}>
        {sections}
      </div>
    );
  };

  const customTheme = {
    yearColor: '#005dbf',
    // lineColor: "#005dbf",
    lineColor: '#005dbf',
    dotColor: '#005dbf',
    borderDotColor: '#d0cdc4',
    titleColor: '#58595b',
    subtitleColor: '#005dbf',
    textColor: 'gray',
  };

  return (
    <>
      <ModalNew
        title=''
        show={showDoc.show}
        btnNoName={'Cancelar'}
        size='700'
        btnYesDisabled={false}
        onHide={() => setShowDoc({ ...showDoc, show: false })}
        btnNoEvent={() => setShowDoc({ ...showDoc, show: false })}
        btnNoDisabled={false}
      >
        <PdfViewer downloadable file={showDoc.url}></PdfViewer>
      </ModalNew>

      <ModalNew
        title='Contratos'
        show={showContract}
        btnNoName={'Cancelar'}
        size='700'
        btnYesDisabled={false}
        onHide={() => setShowContract(false)}
        btnNoEvent={() => setShowContract(false)}
        btnNoDisabled={false}
      >
        <PdfViewer downloadable file={store?.partnersReducer?.urlContract}></PdfViewer>
      </ModalNew>
      <div className={tableStyles.container}>
        <Row className='mt-3 d-flex'>
          <Col xs={8} className='d-flex'>
            <div
              style={{
                height: '75px',
                alignSelf: 'end',
                marginLeft: '2rem',
                width: '2.5%',
                marginRight: '24px',
              }}
            >
              <img
                height={32}
                width={32}
                onClick={() => handleBack()}
                src={backArrow}
                alt='icon'
                className='pointer'
              />
            </div>

            <img
              alt='icon'
              className={`${tableStyles.profileContainer} mt-5 mr-3`}
              src={employeeInfo?.photo ? employeeInfo.photo : anonymus}
            />

            <div className='mt-5'>
              <h1 className={`${tableStyles.title} mb-0 pt-0`}>{employeeInfo?.full_name}</h1>
              <h6 className={`${tableStyles.darkBlueText}`}>
                <b>{employeeInfo?.job_title}</b>
              </h6>
            </div>
          </Col>

          <Col
            xs={3}
            className='d-flex justify-content-end'
            style={{ width: '95%', alignSelf: 'end' }}
          >
            {location.pathname !== '/contabilidad/terceros/empleados/detalleCurriculo' && (
              <img
                alt=''
                width='25px'
                onClick={() =>
                  history.push({
                    pathname: '/nomina/colaborador/nuevo',
                    state: {
                      isEditing: true,
                      employeeToEdit: employeeInfo,
                      currentPercentage,
                    },
                  })
                }
                src={grayPen}
                className={`cursorPointer mr-1`}
              />
            )}
            {/* /* -------------------------------- PDF Viwer ------------------------------- */}

            <ModalNew
              title='Hoja de vida'
              show={showPdf}
              btnNoName={'Cancelar'}
              size='700'
              btnYesDisabled={false}
              onHide={() => setShowPdf(false)}
              btnNoEvent={() => setShowPdf(false)}
              btnNoDisabled={false}
            >
              <PdfViewer downloadable file={`data:application/pdf;base64,${base64}`}></PdfViewer>
            </ModalNew>

            <Tooltip title='Imprimir'>
              <img
                width='25px'
                src={printer}
                alt=''
                className={`cursorPointer mr-2`}
                onClick={() => getPdf()}
              ></img>
            </Tooltip>
          </Col>
        </Row>

        <div className={tableStyles.tableArea}>
          <Row style={{ paddingLeft: '7px' }}>
            <Col xs={12} className={`${tableStyles.weirdBackGround2} mt-3 m-0 p-0`}>
              <Nav variant='tabs' style={{ background: 'aliceblue' }}>
                <Nav.Item>
                  <Nav.Link
                    onClick={() =>
                      setSlider({
                        infoGen: true,
                        attached: false,
                        discounts: false,
                        devAndMove: false,
                        endowment: false,
                        examns: false,
                      })
                    }
                    eventKey='link-1'
                    className={tableStyles.darkBlueText}
                    style={{
                      color: slider.infoGen ? '#495057' : '',
                      backgroundColor: slider.infoGen ? '#fff' : '',
                      borderColor: slider.infoGen ? '#dee2e6 #dee2e6 #fff' : '',
                    }}
                  >
                    {' '}
                    Información general
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    onClick={() =>
                      setSlider({
                        infoGen: false,
                        attached: true,
                        discounts: false,
                        devAndMove: false,
                        endowment: false,
                        examns: false,
                      })
                    }
                    eventKey='link-2'
                    style={{
                      color: '#005dbf',
                    }}
                  >
                    Anexos
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link
                    onClick={() => {
                      setSlider({
                        ...slider,
                        infoGen: false,
                        attached: false,
                        discounts: true,
                        devAndMove: false,
                        endowment: false,
                        examns: false,
                      });
                    }}
                    style={{
                      color: '#005dbf',
                    }}
                    eventKey='link-3'
                  >
                    Descuentos y libranzas
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    onClick={() => {
                      setSlider({
                        ...slider,
                        infoGen: false,
                        attached: false,
                        discounts: false,
                        devAndMove: true,
                        endowment: false,
                        examns: false,
                      });
                    }}
                    eventKey='link-4'
                    style={{
                      color: '#005dbf',
                    }}
                  >
                    Desarrollo y motivación
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    onClick={() => {
                      setSlider({
                        ...slider,
                        infoGen: false,
                        attached: false,
                        discounts: false,
                        devAndMove: false,
                        endowment: true,
                        examns: false,
                      });
                    }}
                    eventKey='link-5'
                    style={{
                      color: '#005dbf',
                    }}
                  >
                    Dotación
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    onClick={() => {
                      setSlider({
                        ...slider,
                        infoGen: false,
                        attached: false,
                        discounts: false,
                        devAndMove: false,
                        endowment: false,
                        examns: true,
                      });
                    }}
                    eventKey='link-6'
                    style={{
                      color: '#005dbf',
                    }}
                  >
                    <div className={'d-flex'}>
                      <div>Exámenes médicos &nbsp;</div>
                    </div>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            {slider.infoGen && (
              <>
                <Col xs={4} className='mt-4'>
                  <h3 className={tableStyles.darkBlueText}>
                    <b>Datos básicos</b>
                  </h3>
                  <div className='mt-4'>
                    <Row className='d-flex justify-content-start'>
                      <Col xs={6} className={`${tableStyles.darkGrayText} ${tableStyles.f13} `}>
                        <b>Nacionalidad</b>
                      </Col>

                      <Col xs={6} className={`text-secondary ${tableStyles.f12}`}>
                        {employeeInfo?.country_name}
                      </Col>
                    </Row>

                    <Row className='d-flex justify-content-start'>
                      <Col xs={6} className={`${tableStyles.darkGrayText} ${tableStyles.f13} `}>
                        <b>Tipo de documento</b>
                      </Col>
                      <Col xs={6} className={`text-secondary ${tableStyles.f12}`}>
                        {employeeInfo?.document_type}
                      </Col>
                    </Row>

                    <Row className='d-flex justify-content-start'>
                      <Col xs={6} className={`${tableStyles.darkGrayText} ${tableStyles.f13} `}>
                        <b>Número de documento</b>
                      </Col>
                      <Col xs={6} className={`text-secondary ${tableStyles.f12}`}>
                        {employeeInfo?.doc_number ? formatteDocument(employeeInfo?.doc_number).format : '-'}
                      </Col>
                    </Row>

                    <Row className='d-flex justify-content-start'>
                      <Col xs={6} className={`${tableStyles.darkGrayText} ${tableStyles.f13} `}>
                        <b>Expedición</b>
                      </Col>
                      <Col xs={6} className={`text-secondary ${tableStyles.f12}`}>
                        {employeeInfo?.doc_issued}
                      </Col>
                    </Row>

                    <Row className='d-flex justify-content-start'>
                      <Col xs={6} className={`${tableStyles.darkGrayText} ${tableStyles.f13} `}>
                        <b>Fecha de nacimiento</b>
                      </Col>
                      <Col xs={6} className={`text-secondary ${tableStyles.f12}`}>
                        {moment(employeeInfo?.birthdate).format('YYYY/MM/DD')}
                      </Col>
                    </Row>

                    <Row className='d-flex justify-content-start'>
                      <Col xs={6} className={`${tableStyles.darkGrayText} ${tableStyles.f13} `}>
                        <b>Edad</b>
                      </Col>
                      <Col xs={6} className={`text-secondary ${tableStyles.f12}`}>
                        {employeeInfo?.age ?? '-'}
                      </Col>
                    </Row>

                    <Row className='d-flex justify-content-start'>
                      <Col xs={6} className={`${tableStyles.darkGrayText} ${tableStyles.f13} `}>
                        <b>Género</b>
                      </Col>
                      <Col xs={6} className={`text-secondary ${tableStyles.f12}`}>
                        {employeeInfo?.gender_label}
                      </Col>
                    </Row>

                    <Row className='d-flex justify-content-start'>
                      <Col xs={6} className={`${tableStyles.darkGrayText} ${tableStyles.f13} `}>
                        <b>Est. Civil</b>
                      </Col>
                      <Col xs={6} className={`text-secondary ${tableStyles.f12}`}>
                        {employeeInfo?.marital_status === 'single'
                          ? 'Soltero(a)'
                          : employeeInfo?.marital_status === 'married'
                            ? 'Casado(a)'
                            : employeeInfo?.marital_status === 'divorced'
                              ? 'Divorciado(a)'
                              : employeeInfo?.marital_status === 'widowed'
                                ? 'Viudo(a)'
                                : employeeInfo?.marital_status === 'freeunion'
                                  ? 'Unión libre'
                                  : employeeInfo?.marital_status === 'others'
                                    ? 'Otro'
                                    : '-'}
                      </Col>
                    </Row>

                    <Row className='d-flex justify-content-start'>
                      <Col xs={6} className={`${tableStyles.darkGrayText} ${tableStyles.f13} `}>
                        <b>No. de hijos</b>
                      </Col>
                      <Col xs={6} className={`text-secondary ${tableStyles.f12}`}>
                        {employeeInfo?.children_qty ? employeeInfo?.children_qty : '-'}
                      </Col>
                    </Row>

                    <Row className='d-flex justify-content-start'>
                      <Col xs={6} className={`${tableStyles.darkGrayText} ${tableStyles.f13} `}>
                        <b>Fecha de ingreso</b>
                      </Col>
                      <Col xs={6} className={`text-secondary ${tableStyles.f12}`}>
                        {moment(employeeInfo?.start_date).format('DD/MM/YYYY')}
                      </Col>
                    </Row>
                    <Row className='d-flex justify-content-start'>
                      <Col xs={6} className={`${tableStyles.darkGrayText} ${tableStyles.f13}`}>
                        <b>Método de pago</b>
                      </Col>
                      <Col xs={6} className={`text-secondary ${tableStyles.f12}`}>
                        {employeeInfo?.payment_method ?? '-'}
                      </Col>
                    </Row>
                    {employeeInfo?.payment_method === 'Transferencia' && (
                      <>
                        <Row className='d-flex justify-content-start'>
                          <Col xs={6} className={`${tableStyles.darkGrayText} ${tableStyles.f13}`}>
                            <b>No. cuenta</b>
                          </Col>
                          <Col xs={6} className={`text-secondary ${tableStyles.f12}`}>
                            {employeeInfo.bank_account_nbr ?? '-'}
                          </Col>
                        </Row>
                        <Row className='d-flex justify-content-start'>
                          <Col xs={6} className={`${tableStyles.darkGrayText} ${tableStyles.f13}`}>
                            <b>Tipo de cuenta</b>
                          </Col>
                          <Col xs={6} className={`text-secondary ${tableStyles.f12}`}>
                            {employeeInfo.bank_account_type ?? '-'}
                          </Col>
                        </Row>
                        <Row className='d-flex justify-content-start'>
                          <Col xs={6} className={`${tableStyles.darkGrayText} ${tableStyles.f13}`}>
                            <b>Banco</b>
                          </Col>
                          <Col xs={6} className={`text-secondary ${tableStyles.f12}`}>
                            {employeeInfo.bankingName ?? '-'}
                          </Col>
                        </Row>
                      </>
                    )}
                  </div>
                  <h3 className={`${tableStyles.darkBlueText} mt-3`}>
                    <b>Contacto</b>
                  </h3>
                  <div className='mt-4'>
                    <Row className='d-flex justify-content-start'>
                      <Col xs={6} className={`${tableStyles.darkGrayText} ${tableStyles.f13} `}>
                        <b>Télefono</b>
                      </Col>
                      <Col xs={6} className={`text-secondary ${tableStyles.f12}`}>
                        {employeeInfo?.mobile}
                      </Col>
                    </Row>

                    <Row className='d-flex justify-content-start'>
                      <Col xs={6} className={`${tableStyles.darkGrayText} ${tableStyles.f13} `}>
                        <b>Email</b>
                      </Col>
                      <Col
                        xs={6}
                        className={`text-secondary ${tableStyles.f12}`}
                        style={{ wordBreak: 'break-all' }}
                      >
                        {employeeInfo?.email}
                      </Col>
                    </Row>

                    <Row className='d-flex justify-content-start'>
                      <Col xs={6} className={`${tableStyles.darkGrayText} ${tableStyles.f13} `}>
                        <b>Domicilio</b>
                      </Col>
                      <Col xs={6} className={`text-secondary ${tableStyles.f12}`}>
                        {employeeInfo?.home_address}
                      </Col>
                    </Row>

                    <Row className='d-flex justify-content-start'>
                      <Col xs={6} className={`${tableStyles.darkGrayText} ${tableStyles.f13} `}>
                        <b>Cont. de emergencia</b>
                      </Col>
                      <Col xs={6} className={`text-secondary ${tableStyles.f12}`}>
                        {employeeInfo?.emergency_contact}
                      </Col>
                    </Row>

                    <Row className='d-flex justify-content-start'>
                      <Col xs={6} className={`${tableStyles.darkGrayText} ${tableStyles.f13} `}>
                        <b>Parentesco</b>
                      </Col>
                      <Col xs={6} className={`text-secondary ${tableStyles.f12}`}>
                        {employeeInfo?.emercont_relationship === 'partner'
                          ? 'Pareja'
                          : employeeInfo?.emercont_relationship === 'parent'
                            ? 'Padre/madre'
                            : employeeInfo?.emercont_relationship === 'son'
                              ? 'Hijo(a)'
                              : employeeInfo?.emercont_relationship === 'sibling'
                                ? 'Hermano(a)'
                                : '-'}
                      </Col>
                    </Row>

                    <Row className='d-flex justify-content-start'>
                      <Col xs={6} className={`${tableStyles.darkGrayText} ${tableStyles.f13} `}>
                        <b>Teléfono</b>
                      </Col>
                      <Col xs={6} className={`text-secondary ${tableStyles.f12}`}>
                        {employeeInfo?.emercont_mobile ? employeeInfo?.emercont_mobile : '-'}
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col xs={4} className={`pt-4 mb-5 ${tableStyles.bgColorClearBlue} `}>
                  <div className='mt-3 mb-2' style={{ height: '14.5rem', marginLeft: '10%' }}>
                    <p className={tableStyles.darkBlueText}>
                      <b>
                        Contrato
                        <img
                          style={{ width: '4%' }}
                          onClick={() => {
                            setShowContract(true);
                          }}
                          src={link}
                          className={`ml-3 cursorPointer pb-2`}
                          alt=''
                        ></img>
                      </b>
                    </p>
                    <Row className='d-flex justify-content-start'>
                      <Col xs={6} className={`${tableStyles.darkGrayText} ${tableStyles.f13} `}>
                        <b>Tipo de contrato</b>
                      </Col>
                      <Col xs={6} className={`text-secondary ${tableStyles.f12}`}>
                        {employeeInfo?.description}
                      </Col>
                    </Row>

                    <Row className='d-flex justify-content-start'>
                      <Col xs={6} className={`${tableStyles.darkGrayText} ${tableStyles.f13} `}>
                        <b>Fecha de inicio</b>
                      </Col>
                      <Col xs={6} className={`text-secondary ${tableStyles.f12}`}>
                        {moment(employeeInfo?.start_date).format('DD/MM/YYYY')}
                      </Col>
                    </Row>

                    <Row className='d-flex justify-content-start'>
                      <Col xs={6} className={`${tableStyles.darkGrayText} ${tableStyles.f13} `}>
                        <b>Vigencia hasta</b>
                      </Col>
                      <Col xs={6} className={`text-secondary ${tableStyles.f12}`}>
                        {employeeInfo?.end_date ? employeeInfo?.end_date : '-'}
                      </Col>
                    </Row>

                    <Row className='d-flex justify-content-start'>
                      <Col xs={6} className={`${tableStyles.darkGrayText} ${tableStyles.f13} `}>
                        <b>Salario base</b>
                      </Col>
                      <Col xs={6} className={`text-secondary ${tableStyles.f12}`}>
                        {convertMoneyFormat(
                          employeeInfo?.base_salary ? employeeInfo?.base_salary : '0',
                        )}
                      </Col>
                    </Row>
                    <Row className='d-flex justify-content-start'>
                      <Col xs={6} className={`${tableStyles.darkGrayText} ${tableStyles.f13} `}>
                        <b>Salario no constitutivo</b>
                      </Col>
                      <Col xs={6} className={`text-secondary ${tableStyles.f12}`}>
                        {convertMoneyFormat(employeeInfo?.non_const_income ?? '-')}
                      </Col>
                    </Row>

                    <Row className='d-flex justify-content-start'>
                      <Col xs={6} className={`${tableStyles.darkGrayText} ${tableStyles.f13} `}>
                        <b>Sede</b>
                      </Col>
                      <Col xs={6} className={`text-secondary ${tableStyles.f12}`}>
                        {employeeInfo?.site_name}
                      </Col>
                    </Row>
                  </div>

                  <div style={{ marginLeft: '10%' }}>
                    <div className='mt-2'>
                      <p className={tableStyles.darkBlueText}>
                        <b>Educación</b>
                      </p>
                      {employeeInfo?.education?.length > 0 &&
                        employeeInfo?.education?.map((e, i) => {
                          return (
                            <div key={i} className='boxEducation'>
                              <Timeline lang='es' theme={customTheme} dateFormat={'L'}>
                                {e.graduation_date ? (
                                  <Container>
                                    <YearContent
                                      startDate={moment(e.graduation_date ?? today()).format(
                                        'YYYY/MM/DD',
                                      )}
                                    />
                                    <BodyContent>
                                      <Section
                                        title={
                                          <b className={`${tableStyles.darkGrayText}`}>
                                            {e.education_type ?? '-'}
                                          </b>
                                        }
                                      >
                                        <Description text={e.institution_name ?? '-'} />

                                        <Description
                                          variant='subtitle'
                                          text={e.description ?? '-'}
                                        />
                                      </Section>
                                      <Section></Section>
                                    </BodyContent>
                                  </Container>
                                ) : (
                                  <></>
                                )}
                              </Timeline>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </Col>
                <Col xs={4} className={`pt-4 mb-5 ${tableStyles.bgColorClearBlue} `}>
                  <div className='mt-3 mb-2 ' style={{ height: '14.5rem' }}>
                    <p className={tableStyles.darkBlueText}>
                      <b>Seguridad y salud</b>
                    </p>
                    <Row className='d-flex justify-content-start'>
                      <Col xs={6} className={`${tableStyles.darkGrayText} ${tableStyles.f13} `}>
                        <b>EPS</b>
                      </Col>
                      <Col xs={6} className={`text-secondary ${tableStyles.f12}`}>
                        {employeeInfo?.hce_description ? employeeInfo?.hce_description : '-'}
                      </Col>
                    </Row>

                    <Row className='d-flex justify-content-start'>
                      <Col xs={6} className={`${tableStyles.darkGrayText} ${tableStyles.f13} `}>
                        <b>Fondo de pensión</b>
                      </Col>
                      <Col xs={6} className={`text-secondary ${tableStyles.f12}`}>
                        {employeeInfo?.pension_description
                          ? employeeInfo?.pension_description
                          : '-'}
                      </Col>
                    </Row>

                    <Row className='d-flex justify-content-start'>
                      <Col xs={6} className={`${tableStyles.darkGrayText} ${tableStyles.f13} `}>
                        <b>ARL</b>
                      </Col>
                      <Col xs={6} className={`text-secondary ${tableStyles.f12}`}>
                        {employeeInfo?.occupational_risk_description
                          ? employeeInfo?.occupational_risk_description
                          : '-'}
                      </Col>
                    </Row>

                    <Row className='d-flex justify-content-start'>
                      <Col xs={6} className={`${tableStyles.darkGrayText} ${tableStyles.f13} `}>
                        <b>Caja de compensación</b>
                      </Col>
                      <Col xs={6} className={`text-secondary ${tableStyles.f12}`}>
                        {employeeInfo?.compensation_center
                          ? employeeInfo?.compensation_center
                          : '-'}
                      </Col>
                    </Row>
                  </div>
                  <div className='mt-2 '>
                    <p className={`mt-1 ${tableStyles.darkBlueText}`}>
                      <b>Experiencia</b>
                    </p>
                    {employeeInfo?.workExperience?.length > 0 &&
                      employeeInfo?.workExperience?.map((e, i) => {
                        return (
                          <Timeline key={i} lang='es' theme={customTheme}>
                            {e.start_date ? (
                              <Container>
                                <YearContent
                                  startDate={moment(e.start_date).format('YYYY/MM/DD')}
                                  endDate={moment(e.end_date).format('YYYY/MM/DD')}
                                />
                                <BodyContent>
                                  <Section
                                    title={
                                      <b className={`${tableStyles.darkGrayText}`}>
                                        {e.company_name}
                                      </b>
                                    }
                                  >
                                    <Description text={<span>{e.description}</span>} />
                                  </Section>
                                  <Section></Section>
                                </BodyContent>
                              </Container>
                            ) : (
                              <></>
                            )}
                          </Timeline>
                        );
                      })}
                  </div>
                </Col>
              </>
            )}
          </Row>
          {slider.attached && <>{renderSections()}</>}
          {slider.discounts && (
            <>
              <DiscountsAndPayout
                idContract={id_contract}
                idCandidate={id_candidate}
              />
            </>
          )}
          {slider.devAndMove && (
            <>
              <DevAndMotivation idCandidate={id_candidate} />
            </>
          )}
          {slider.endowment && (
            <>
              <CurriculumEndowments employeeInfo={employeeInfo} />
            </>
          )}
          {slider.examns && (
            <>
              <Examns idCandidate={id_candidate} />
            </>
          )}
        </div>
      </div>
    </>
  );
};
