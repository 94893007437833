//icons
import arrowRight from '../../../../assets/img/icons/arrowDerechaCalendario.svg';
import arrowLeft from '../../../../assets/img/icons/arrowIzquierdaCalendario.svg';

//styles
import styles from '../styles.module.scss';

//carousel
import Carousel, { consts } from 'react-elastic-carousel';

//helpers
import { customSwaltAlertAsistencial, generateId, renderTooltip } from '../../../../helpers';

//components
import { Box } from '@mui/material';
import { OverlayTrigger } from 'react-bootstrap';

const renderArrow = ({ type, onClick, isEdge }) => {
  const pointer =
    type === consts.PREV ? (
      <img src={arrowLeft} alt='arrow' width={10} />
    ) : (
      <img src={arrowRight} alt='arrow' width={10} />
    );
  return (
    <button onClick={onClick} disabled={isEdge}>
      {pointer}
    </button>
  );
};

export const SearchAppointmentCarousel = ({
  schedules = [],
  currentDate,
  onToggleHourSchedule,
}) => {
  const filteredSchedules = schedules
    .filter(schedule => schedule.hours.length > 0)
    .filter(schedule => schedule.hours[0].date === currentDate);

  return (
    <Box style={{ height: '250px', overflowY: 'auto' }}>
      {filteredSchedules.length ? (
        filteredSchedules.map((schedule, index) => {
          let hourSch = schedule?.time?.split(':')[0];

          return (
            <Box
              mb={1}
              key={index}
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'center'}
              style={{ gap: '10px' }}
            >
              <div className={styles.line}>
                <Box minWidth={20} textAlign={'center'}>
                  <span className={styles.app_title} style={{ paddingRight: '8px' }}>
                    {hourSch}
                  </span>
                </Box>
              </div>

              <Carousel
                itemsToShow={3}
                itemsToScroll={3}
                showArrows={true}
                pagination={false}
                renderArrow={renderArrow}
              >
                {schedule?.hours?.map((hour, index) => {
                  let hourCard = `${hour?.hourStart.slice(0, hour?.hourStart?.length - 3)}`;

                  return (
                    <OverlayTrigger
                      key={generateId()}
                      placement='top'
                      delay={{ show: 250, hide: 400 }}
                      overlay={renderTooltip(hour.nameSite)}
                    >
                      <div
                        key={index}
                        className={`${styles.app_container_map} m-0 justify-content-center`}
                      >
                        <div
                          className={
                            hour?.selected
                              ? styles.app_container_hours_active
                              : hour?.appointment
                                ? styles.app_container_hours_inactive
                                : styles.app_container_hours
                          }
                          onClick={() => {
                            if (!hour?.appointment) {
                              onToggleHourSchedule({
                                idSchedule: hour?.idSchedule,
                                hourStart: hour?.hourStart,
                                hourEnd: hour?.hourEnd,
                                date: hour?.date,
                                time: schedule?.time,
                              });
                            } else if (hour?.appointment && !hour?.selected) {
                              customSwaltAlertAsistencial({
                                icon: 'warning',
                                title: 'Intenta de nuevo',
                                text: 'Seleccione una hora valida',
                                showCancelButton: false,
                              });
                            }
                          }}
                        >
                          <span className={styles.hours}>{hourCard}</span>
                        </div>
                      </div>
                    </OverlayTrigger>
                  );
                })}
              </Carousel>
            </Box>
          );
        })
      ) : (
        <div className='d-flex h-100 justify-content-center justify-align-center align-middle align-self-center'>
          <div className='fw-bold d-flex w-100 h-100 justify-content-center align-items-center'>
          <span className={styles.app_title_card} style={{ textAlign: 'center' }}>
              No hay agendas disponibles
            </span>
          </div>
        </div>
      )}
    </Box>
  );
};
