// icons
import { AddCircleOutline } from '@material-ui/icons';
import ThreeDots from '../../../assets/img/icons/threeDots.svg';

// scss
import treasuryStyles from './payment-types.module.scss';
import paginationStyles from '../../../components/Layouts/pagination.module.scss';

// components
import { useCallback, useEffect, useMemo, useState } from 'react';
import Select from 'react-select';

import { PaymentTypesConfig } from './config/';
import GenericTableNew from '../../../components/Layouts/GenericTableNew';
import CustomPopupExtend from '../../../components/Popup/customPopUpExtends';
import { ordCustomSelect } from '../../../components/Layouts/react-select-custom';
import Pagination from 'react-js-pagination';
import { customSwaltAlert } from '../../../helpers';

// services
import { useGetMethod, usePostMethod } from '../../../Hooks';
import { useSelector } from 'react-redux';
import { loader } from '../../../helpers';

export const PaymentTypesTreasury = () => {
  const store = useSelector(state => state);
  const token = store.loginReducer.Authorization;
  const eaccount = store.loginReducer.currentAccount.id;

  const [show, setShowConfig] = useState(false);

  const config = useMemo(() => {
    return { token, eaccount, show };
  }, [eaccount, show, token]);

  const handleShowConfig = () => setShowConfig(true);

  const handleCloseConfig = useCallback(() => setShowConfig(false), []);

  const initialStateFilters = {
    page: 1,
    perpage: 10,
    eaccount,
    status: undefined,
    id: undefined,
  };

  const [filters, setFilters] = useState(initialStateFilters);

  const {
    results: listPaymentTypes,
    load: loaderListPaymentTypes,
    trigger: getListPaymentTypes,
  } = useGetMethod();

  const {
    results: paymentTypes,
    load: loaderPaymentTypes,
    trigger: getPaymentTypes,
  } = useGetMethod();

  const { trigger: putData, load: loadSendDataForm } = usePostMethod();

  const listPaymentTypesSelect = useMemo(() => {
    const data = [{ label: 'Seleccionar...', value: '' }];

    if (paymentTypes?.data?.length) {
      data.push(
        ...paymentTypes.data.map(item => ({
          value: item.id,
          label: item.paymentType,
        })),
      );
    }

    return data;
  }, [paymentTypes.data]);

  const listStatusSelect = [
    { label: 'Seleccionar...', value: '' },
    { label: 'Habilitado', value: 'enabled' },
    { label: 'Deshabilitado', value: 'disabled' },
  ];

  const onUpdateSuccess = useCallback(() => {
    setFilters({ ...filters, page: 1 });
    getPaymentTypes({
      token,
      url: '/cash/payment_types',
      objFilters: { eaccount },
    });

    getListPaymentTypes({
      token,
      url: '/cash/payment_types',
      objFilters: { page: 1, perpage: 10, eaccount },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getListPaymentTypes, token, eaccount]);

  useEffect(() => {
    getPaymentTypes({
      token,
      url: '/cash/payment_types',
      objFilters: { eaccount },
    });

    getListPaymentTypes({
      token,
      url: '/cash/payment_types',
      objFilters: { page: 1, perpage: 10, eaccount },
    });

  }, [eaccount, getListPaymentTypes, getPaymentTypes, token]);

  const onChangeStatus = data => {
    putData({
      url: `/cash/payment_types/change-status/${data.id}`,
      token: token,
      noAlert: true,
      method: 'PUT',
      body: { status: data.statusPrefix === 'enabled' ? 'disabled' : 'enabled' },
      succesAction: () => {
        customSwaltAlert({
          icon: 'success',
          title: 'Actualizado exitosamente',
          text: `Se ha ${data.statusPrefix === 'enabled' ? 'deshabilitado' : 'habilitado'} el tipo de pago: ${data.paymentType}`,
          showCancelButton: false,
        }).finally(() => {
          getListPaymentTypes({
            token,
            url: '/cash/payment_types',
            objFilters: { ...initialStateFilters },
          });
        });

        // onHideModalCopago();
      },
      doAfterException: error => {
        customSwaltAlert({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: error.message,
          showCancelButton: false,
        });
      },
    });
  };

  //////// HEADERS TABLE ////////////
  const headerTable = [
    <th key={1} className='text-center col-1'>
      Código
    </th>,

    <th key={2} className='text-center col-8'>
      Tipo de pago
    </th>,

    <th key={3} className='text-center '>
      Estado
    </th>,

    <th key={4} className='text-end'></th>,
  ];

  //////// BODY TABLE ////////////
  const bodyTable = array => {
    let tempList = [];
    if (Array.isArray(array)) {
      array.forEach(item => {
        tempList.push(
          <tr key={item.id} className={`hover-table-row`}>
            <td className='px-2 text-center'>{item.id}</td>
            <td className='px-2 text-center'>{item.paymentType || '-'}</td>
            <td className='px-2 text-center'>
              <div
                className={treasuryStyles.cardStatus}
                style={{ color: item.fontColor, background: item.bgColor }}
              >
                {item.statusName}
              </div>
            </td>
            <td className='px-2 text-end'>
              <CustomPopupExtend
                noHover
                showEnable
                isEnabled={item.statusPrefix === 'enabled'}
                position='right'
                triggerSrc={ThreeDots}
                enableClickEvent={() => {
                  customSwaltAlert({
                    icon: 'warning',
                    title: '¿Está seguro?',
                    text: `Se ${item.statusPrefix === 'enabled' ? 'deshabilitará' : 'habilitará'} el tipo de pago: ${item.paymentType}`,
                    confirmButtonText: 'Si, continuar',
                    cancelButtonText: 'Cancelar',
                    showCancelButton: true,
                  }).then(result => {
                    if (result.isConfirmed) {
                      onChangeStatus(item);
                    }
                  });
                }}
              />
            </td>
          </tr>,
        );
      });
    }
    return tempList;
  };

  const renderFilters = () => {
    return (
      <div>
        <h1>Tipos de pago</h1>
        <div className={treasuryStyles.filtersGrid}>
          <div>
            <div>Tipo de pago</div>
            <Select
              styles={ordCustomSelect}
              className='text-secondary '
              placeholder={'Seleccionar...'}
              options={listPaymentTypesSelect}
              noOptionsMessage={() => 'No hay datos'}
              onChange={el => {
                getListPaymentTypes({
                  token,
                  url: '/cash/payment_types',
                  objFilters: { ...filters, id: el.value, page: 1 },
                });

                setFilters(state => ({
                  ...state,
                  page: 1,
                  id: el.value,
                }));
              }}
            />
          </div>
          <div>
            <div>Estado</div>
            <Select
              styles={ordCustomSelect}
              className='text-secondary '
              placeholder={'Seleccionar...'}
              options={listStatusSelect}
              noOptionsMessage={() => 'No hay datos'}
              onChange={el => {
                getListPaymentTypes({
                  token,
                  url: '/cash/payment_types',
                  objFilters: { ...filters, status: el.value, page: 1 },
                });

                setFilters(state => ({
                  ...state,
                  page: 1,
                  status: el.value,
                }));
              }}
            />
          </div>
          <div className={treasuryStyles.boxInputSearch}>
          </div>

          <button
            type='button'
            style={{ justifySelf: 'end', columnGap: 10 }}
            className={treasuryStyles.btnPrimaryFilters}
            onClick={handleShowConfig}
          >
            <span>Modificar tipo de pago</span>
            <AddCircleOutline stroke={3} fontSize='small' />
          </button>
        </div>
      </div>
    );
  };

  const renderTable = () => {
    return (
      <GenericTableNew dark={true} headers={headerTable}>
        {bodyTable(listPaymentTypes.data)}
      </GenericTableNew>
    );
  };

  const main = () => {
    return (
      <>
        {(loaderListPaymentTypes || loadSendDataForm || loaderPaymentTypes) && loader}

        <div className={treasuryStyles.container}>
          {renderFilters()}
          {renderTable()}

          <div className={paginationStyles.wrapper}>
            <p className={paginationStyles.paginationText}>
              Pag. {filters.page}
              {' de '}
              {Math.ceil(listPaymentTypes?.rowTotal / filters.perpage)
                ? Math.ceil(listPaymentTypes?.rowTotal / filters.perpage)
                : '1'}{' '}
              ({listPaymentTypes?.rowTotal} encontrados)
            </p>
            <Pagination
              activePage={filters.page}
              itemsCountPerPage={filters.perpage}
              totalItemsCount={listPaymentTypes?.rowTotal}
              pageRangeDisplayed={5}
              onChange={page => {
                setFilters({ ...filters, page: page });

                getListPaymentTypes({
                  token,
                  url: '/cash/payment_types',
                  objFilters: { ...filters, page: page },
                });
              }}
              itemClassPrev={paginationStyles.itemClassPrev}
              itemClassNext={paginationStyles.itemClassNext}
              itemClassFirst={paginationStyles.itemClassFirst}
              itemClassLast={paginationStyles.itemClassLast}
              itemClass={paginationStyles.itemClass}
            />
          </div>

          <PaymentTypesConfig
            config={config}
            handleClose={handleCloseConfig}
            onUpdateSuccess={onUpdateSuccess}
          />
        </div>
      </>
    );
  };

  return main();
};
