import moment from 'moment';
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const CalendarEvent = (e, selectedOption, objSite, objDoctor) => {
  let hourStart;
  let hourEnd;
  let am = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11'];

  if (selectedOption === 'week') {
    hourStart = moment(e.event.beginsAt).format('LT');
    hourEnd = moment(e.event.endsAt).format('LT');

    am.includes(hourStart.split(':')[0]) ? (hourStart += ' AM') : (hourStart += ' PM');
    am.includes(hourEnd.split(':')[0]) ? (hourEnd += ' AM') : (hourEnd += ' PM');
  }

  return (
    <>
      {selectedOption === 'month' && objDoctor?.mdName.length >= 1 && (
        <>
          {e.event.enabled === 0 ? (
            <span style={{ color: '#77787a', outline: 'none' }}>AGENDA BLOQUEADA</span>
          ) : (
            <span>{e.event.name}</span>
          )}
        </>
      )}

      {selectedOption === 'month' && objSite?.site.length >= 1 && e.event.enabled === 0 ? (
        <span style={{ color: '#77787a', outline: 'none' }}>AGENDA BLOQUEADA</span>
      ) : (
        <OverlayTrigger
          placement='top'
          delay={{ show: 250, hide: 250 }}
          overlay={
            <Tooltip title={e.event.consultingRoomName}>
              <div>Consultorio: {e.event.consultingRoomName}</div>
            </Tooltip>
          }
        >
          <span>{e.event.name}</span>
        </OverlayTrigger>
      )}

      {selectedOption === 'week' && (
        <div className='boxDataInfoWeek'>
          <div className='tittleGridItemInfoWeek' style={{ color: e.event.color }}>
            <span>
              {hourStart} - {hourEnd}
            </span>
          </div>

          <div className='gridItemInfoWeek'>
            <span className='fontBold'>Sede</span>
            <span>{e.event.siteDescription}</span>
          </div>

          <div className='gridItemInfoWeek'>
            <span className='fontBold'>Consultorio</span>
            <span>
              {e.event.consultingRoomName} - {e.event.consultingRoom}
            </span>
          </div>
        </div>
      )}
    </>
  );
};

export default CalendarEvent;
