//import Reacts natives && React Router
import { useState } from 'react';
import { useHistory  } from 'react-router';

//import hooks
import { useFormatData, useGetMethod } from '../../Hooks/';

//import SASS && Mui material && icons
import { Box } from '@mui/material';
import tableStyle from '../../components/Layouts/tableStyle.module.scss';

//import Icons
import adjuntar from '../../assets/img/icons/AdjuntarRecto.svg';
import iconClose from '../../assets/img/icons/close_insurer_voluntary.svg';
import countNotes from '../../assets/img/icons/countNotes.svg';
import download from '../../assets/img/icons/downloadArrow2.svg';
import downloadPdf from '../../assets/img/icons/downloadPdf.svg';
import downloadPdfText from '../../assets/img/icons/downloadPdfText.svg';

//import Helpers
import { customSwaltAlertAsistencial } from './../../helpers/customSwaltAlertAsistencial';
import { getFilenameByURL, loader, onChangeFile } from './../../helpers/helpers';

//import Components
import moment from 'moment';
import { useEffect, useMemo } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { useSelector } from 'react-redux';
import { OrdGenericTemplate } from '../../OrderingModule/OrderingComponents/OrdGenericTemplate';
import OrdModal from '../../OrderingModule/OrderingComponents/OrdModal';
import OrdTable from '../../OrderingModule/OrderingComponents/OrdTable';
import { DrawerHistorialChat, ServiceOrderCart, TableBills } from './components';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

export const ServiceOrderDetail = () => {
  // STATES
  const history = useHistory();
  const store = useSelector(state => state);
  const token = store.loginReducer.Authorization;
  const eaccount = store.loginReducer.currentAccount.id;
  const siteId = store.userReducer.site;
  const userId = store.loginReducer.user_data.id;
  const { id: orderId } = useParams();

  const [data, setData] = useState({ showModal: false, showDrawer: false });

  const [activeDocument, setActiveDocument] = useState({
    show: false,
    fileName: '',
    fileUrl: '',
  });

  //////// HTTPS GET ////////////
  const { results: base64, load: loaderGetBase64, trigger: getBase64 } = useGetMethod();

  const {
    load: loadServiceOrderDetail,
    results: serviceOrderDetail,
    trigger: getServiceOrderDetail,
  } = useGetMethod();

  const {
    results: patientComments,
    load: loadPatientComments,
    trigger: getPatientComments,
  } = useGetMethod();

  const dataService = useMemo(
    () => serviceOrderDetail?.results ?? {},
    [serviceOrderDetail?.results],
  );

  // TABLE
  const tableHeader = [
    {
      title: 'Documento',
      className: 'px-2 col-4',
    },
    {
      title: 'Fecha adjunto',
      className: 'text-center px-2 col-4',
    },
    {
      title: 'Estado',
      className: ' text-center px-2 col-2',
    },

    {
      title: ' ',
      className: 'px-2 col-2',
    },
  ];
  const [lastDocument, setLastDocument] = useState('');

  const onToggleDocument = document => {
    if (lastDocument !== document?.fileName) {
      setActiveDocument(state => ({
        ...state,
        show: true,
        fileName: document?.fileName,
        fileUrl: document?.fileUrl,
      }));
    } else {
      setActiveDocument(state => ({
        ...state,
        show: !state.show,
        fileName: document?.fileName,
        fileUrl: document?.fileUrl,
      }));
    }
    setLastDocument(document?.fileName);
  };

  const tableBodyStructure = item => [
    {
      text: (
        <div className='pointer' onClick={() => onToggleDocument(item)}>
          {item?.fileName || '-'}
        </div>
      ),
      className: `text-start px-2`,
    },
    {
      text: <>{moment(item?.modificationDate).format('YYYY-MM-DD') || '-'}</>,
      className: 'text-center px-2',
    },
    {
      text: (
        // ordGreenBgButton
        <div className={`${tableStyle.ordRedBgButton}`}>inválido</div>
      ),
      className: 'text-center',
    },
    {
      text: (
        <div className='d-flex justify-content-around px-2'>
          <img
            alt='adjuntó'
            className={`${tableStyle.filterBlockAdmission} pointer`}
            height={20}
            src={adjuntar}
            width={10}
            onClick={() => editDocument(item)}
          />

          <div className='pointer d-flex' onClick={() => onOpenDrawer(item)}>
            <img
              alt='notes'
              height={20}
              src={countNotes}
              width={20}
              className={`${tableStyle.filterOrdAquaMarine}`}
            />
            <span className={`${tableStyle.textCountNotes} ${tableStyle.filterWhite}`}>
              {patientComments?.rowTotal || 0}
            </span>
          </div>
        </div>
      ),
      className: '',
    },
  ];

  const { formatData } = useFormatData({
    tableBodyStructure,
    listTableBody: dataService?.supportFiles || [],
  });

  // ACTIONS
  const onStateModal = stateModal => setData(state => ({ ...state, showModal: stateModal }));

  const onOpenDrawer = () => {
    void getPatientComments({
      url: '/medical/comments/',
      objFilters: {
        id: dataService?.patient?.id,
        entity: 'userClient',
      },
      token: token,
    });

    setData(state => ({ ...state, showDrawer: true }));
  };

  const onClearData = () => setData({});

  const clearDocument = () =>
    setData(state => ({ ...state, fileName: '', filetype: '', File: '' }));

  const editDocument = ({ fileName }) => {
    customSwaltAlertAsistencial({
      icon: 'warning',
      title: '¿Esta seguro?',
      text: `Se editará el documento: ${fileName}`,
      showCancelButton: true,
    }).then(({ isConfirmed }) => {
      if (isConfirmed) onStateModal(true);
    });
  };

  const changeDocument = document => {
    const dataFile = onChangeFile(document);

    dataFile.then(el => {
      setData(state => ({
        ...state,
        ...el,
      }));
    });
  };

  const onGetBase64 = () => {
    getBase64({
      token: token,
      url: `/medical/admissions/service-orders/${dataService?.id}/payments/`,
      objFilters: {
        eaccount,
        siteId,
        userId,
      },
    }).then(res => {
      if (!res?.success) {
        return customSwaltAlertAsistencial({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: res.message,
          showCancelButton: false,
        });
      } else {
        document.querySelector('#orderDownloadedPdf').click();
      }
    });
  };

  const RenderDownloadPdf = () => {
    return (
      <>
        <a
          hidden
          id='orderDownloadedPdf'
          download={`factura_${dataService?.id}_${dataService?.date}`}
          href={`data:application/pdf;base64,${base64?.results?.base64?.split("'")[1]}`}
        >
          &nbsp;
        </a>

        <div
          className='align-items-end d-flex gap-2'
          onClick={() => {
            onGetBase64();
          }}
        >
          <img className='pointer' src={downloadPdf} alt='descargar factura' />

          <img className='pointer' src={downloadPdfText} alt='descargar factura' />
        </div>
      </>
    );
  };

  // EFFECTS
  useEffect(() => {
    if (dataService === undefined) {
      const idSplit = history.location.pathname.split('/');
      const id = idSplit[idSplit.length - 1];

      customSwaltAlertAsistencial({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: `La orden número ${id}. No se encuentra en el listado de ordenes de servicio`,
        showCancelButton: false,
      });

      history.goBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataService]);

  useEffect(() => {
    getServiceOrderDetail({
      token: token,
      url: `/medical/service-orders/${orderId}`,
    });
  }, [token, getServiceOrderDetail, orderId]);

  return (
    <>
      {(loaderGetBase64 || loadServiceOrderDetail || loadPatientComments) && loader}

      <OrdGenericTemplate
        className={`${tableStyle.app_mr_100}`}
        showBackArrow
        title={'Detalle de orden'}
        extraHeader={!dataService?.provider ? <RenderDownloadPdf /> : ''}
        backArrowAction={() => {
          if (dataService?.depositTypeFilter) {
            history.push({
              pathname: `/admision/detalleCierreDiario/`,
              state: { depositTypeFilter: dataService?.depositTypeFilter, page: dataService?.page },
            });
          } else {
            history.goBack();
          }
        }}
      >
        <Box className={`${tableStyle.ml2rem} mb-5`}>
          <Box className={`${tableStyle.ordAquaMarineText} ${tableStyle.fs18px} fw-bold mb-3`}>
            Información del paciente
          </Box>

          <ServiceOrderCart data={dataService} provider={dataService?.provider} />

          <Box display={'flex'}>
            <span className={`${tableStyle.ordDarkGrayText} ${tableStyle.fs13px}`}>
              Registrado por:
              <span className='fw-bold'>
                &nbsp;
                {dataService?.provider
                  ? dataService?.admisionist?.name
                  : dataService?.admittedByName || '-'}
                {!dataService?.provider
                  ? `, ${dataService?.admittedDate} ${dataService?.admittedHour}`
                  : ''}
              </span>
            </span>

            {dataService?.provider ? (
              <TableBills listBills={dataService?.bills || []} idOrder={dataService?.id} />
            ) : (
              ''
            )}
          </Box>

          {dataService?.supportFiles?.length ? (
            <Box display={'grid'} gridTemplateColumns={'1fr 1fr'} gap={3} mt={1}>
              <Box
                height={'300px'}
                justifyContent={'center'}
                alignItems={'center'}
                display={'flex'}
              >
                {activeDocument?.show ? (
                  <Box>
                    {['png', 'jpg', 'jpeg', 'svg']?.includes(
                      activeDocument?.fileUrl?.split('.')[
                        activeDocument?.fileUrl?.split('.')?.length - 1
                      ],
                    ) ? (
                      <img
                        className={`${tableStyle.responsiveImg} animate__animated animate__fadeIn `}
                        src={activeDocument?.fileUrl}
                        alt={activeDocument?.fileName}
                      />
                    ) : (
                      <OverlayTrigger
                        placement='top'
                        delay={{ show: 200, hide: 200 }}
                        overlay={<Tooltip>Descargar</Tooltip>}
                      >
                        <a
                          className={tableStyle.linkDownload}
                          href={activeDocument?.fileUrl}
                          download
                        >
                          <span className={`${tableStyle.ordDarkGrayText} ${tableStyle.fs14px}`}>
                            {getFilenameByURL(activeDocument?.fileUrl)}
                          </span>
                          <img height={17} width={17} src={download} alt='descargar documento' />
                        </a>
                      </OverlayTrigger>
                    )}
                  </Box>
                ) : (
                  ''
                )}
              </Box>

              <Box>
                <OrdTable
                  hoverYellow
                  lastBorderNone
                  body={formatData}
                  headers={tableHeader}
                  className='mt-0  w-100'
                />
                <Box display={'flex'} justifyContent={'end'} gap={2}>
                  <button className={`${tableStyle.btnCancelAdmission}`}>Cancelar</button>
                  <button className={`${tableStyle.btnSaveAdmission}`}>Guardar</button>
                </Box>
              </Box>
            </Box>
          ) : (
            ''
          )}
        </Box>
      </OrdGenericTemplate>

      <OrdModal
        title='Nuevo documento'
        show={data?.showModal}
        btnYesName={'Guardar'}
        size={'200'}
        hideCancelButton
        onHide={() => onClearData()}
        btnNoEvent={() => onClearData()}
      >
        <Box>
          <span className={`${tableStyle.ordAquaMarineText}`}>Anexar</span>
          <span className={`${tableStyle.ordOrangeText}`}>*</span>
        </Box>

        <Box>
          <label
            htmlFor='file'
            className={`bg-transparent w-100
                  ${tableStyle.appInputFileAdmission}
                  ${tableStyle.tlnTextGray}
                  ${tableStyle.cursorPointer}
                  ${tableStyle.f14}
                  align-self-end mt-2`}
          >
            <Box display={'flex'} gap={1} alignItems={'center'}>
              <span>{data?.fileName ? data?.fileName : 'Cargar documento'}</span>
              {data?.fileName && (
                <img
                  width={10}
                  src={iconClose}
                  alt='clearFile'
                  className={tableStyle.styleIconCloseFile}
                  onClick={() => clearDocument()}
                />
              )}
            </Box>

            <input
              id='file'
              type='file'
              name='file'
              accept='application/pdf'
              className='d-none w-100'
              placeholder='Cargar documento'
              onChange={document => changeDocument(document)}
            />

            <img
              src={adjuntar}
              alt='agregar archivo'
              height={16}
              className={`${tableStyle.filterOrdAquaMarine}`}
            />
          </label>
        </Box>
      </OrdModal>

      <DrawerHistorialChat
        open={data?.showDrawer}
        onClose={() => setData({ ...data, showDrawer: false })}
        title='Notas'
        onSuccess={() => {
          void getPatientComments({
            url: '/medical/comments/',
            objFilters: {
              id: dataService?.patient?.id,
              entity: 'userClient',
            },
            token: token,
          });
          void getServiceOrderDetail({
            token: token,
            url: `/medical/service-orders/${orderId}`,
          });
          void getPatientComments({});
        }}
        chatList={
          patientComments?.results?.map(comment => ({
            id: comment.id,
            name: comment.userName,
            message: comment.comment,
            userId: comment.userId,
            date: comment.date,
          })) || []
        }
        patientId={dataService?.patient?.id}
      />
    </>
  );
};
