// icons
import { AddCircleOutline } from '@material-ui/icons';
import ThreeDots from '../../../assets/img/icons/threeDots.svg';

// scss
import treasuryStyles from './types-costs-tc.module.scss';
import paginationStyles from '../../../components/Layouts/pagination.module.scss';

// hooks
import { useSelector } from 'react-redux';
import { useGetMethod, usePostMethod } from '../../../Hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';

// components
import Select from 'react-select';
import Pagination from 'react-js-pagination';
import { TypesCostsTcConfig } from './config/TypesCostsTcConfig';
import GenericTableNew from '../../../components/Layouts/GenericTableNew';
import CustomPopupExtend from '../../../components/Popup/customPopUpExtends';
import { ordCustomSelect } from '../../../components/Layouts/react-select-custom';
import { customSwaltAlert, loader } from '../../../helpers';

export const TypesCostsTc = () => {
  const store = useSelector(state => state);
  const token = store.loginReducer.Authorization;
  const eaccount = store.loginReducer.currentAccount.id;

  const [show, setShowConfig] = useState(false);

  const config = useMemo(() => {
    return { token, eaccount, show };
  }, [eaccount, show, token]);

  const handleShowConfig = () => setShowConfig(true);

  const handleCloseConfig = useCallback(() => setShowConfig(false), []);

  const initialStateFilters = {
    page: 1,
    perpage: 10,
    eaccount,
    status: undefined,
    id: undefined,
  };

  const [filters, setFilters] = useState(initialStateFilters);

  const {
    results: listTypesCostsTc,
    load: loaderListTypesCostsTc,
    trigger: getListTypesCostTc,
  } = useGetMethod();

  const {
    results: typesCostsTc,
    load: loaderTypesCostsTc,
    trigger: getTypesCostsTc,
  } = useGetMethod();

  const { trigger: putData, load: loadSendDataForm } = usePostMethod();

  const listTypesCostsTcSelect = useMemo(() => {
    const data = [{ label: 'Seleccionar...', value: '' }];

    if (typesCostsTc?.data?.length) {
      data.push(
        ...typesCostsTc.data.map(item => ({
          value: item.id,
          label: item.name,
        })),
      );
    }

    return data;
  }, [typesCostsTc?.data]);

  const listStatusSelect = [
    { label: 'Seleccionar...', value: '' },
    { label: 'Habilitado', value: 'enabled' },
    { label: 'Deshabilitado', value: 'disabled' },
  ];

  const onUpdateSuccess = useCallback(() => {
    setFilters({ ...filters, page: 1 });

    getTypesCostsTc({
      token,
      url: '/cash/expense_types',
      objFilters: { eaccount },
    });

    getListTypesCostTc({
      token,
      url: '/cash/expense_types',
      objFilters: { page: 1, perpage: 10, eaccount },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getListTypesCostTc, token, eaccount]);

  useEffect(() => {
    getListTypesCostTc({
      token,
      url: '/cash/expense_types',
      objFilters: { ...initialStateFilters },
    });

    getTypesCostsTc({
      token,
      url: '/cash/expense_types',
      objFilters: { ...initialStateFilters },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getListTypesCostTc, getTypesCostsTc, token]);

  const onChangeStatus = data => {
    putData({
      url: `/cash/expense_types/change-status/${data.id}`,
      token: token,
      noAlert: true,
      method: 'PUT',
      body: { status: data.status.value === 'enabled' ? 'disabled' : 'enabled' },
      succesAction: () => {
        customSwaltAlert({
          icon: 'success',
          title: 'Actualizado exitosamente',
          text: `Se ha ${data.status.value === 'enabled' ? 'deshabilitado' : 'habilitado'} el tipo de gasto: ${data.name}`,
          showCancelButton: false,
        }).finally(() => {
          getListTypesCostTc({
            token,
            url: '/cash/expense_types',
            objFilters: { ...initialStateFilters },
          });
        });

        setFilters({ ...initialStateFilters });
      },
      doAfterException: error => {
        customSwaltAlert({
          icon: 'warning',
          title: 'Intenta de nuevo',
          text: error.message,
          showCancelButton: false,
        });
      },
    });
  };

  //////// HEADERS TABLE ////////////
  const headerTable = [
    <th key={1} className='text-center px-2'>
      Código
    </th>,

    <th key={2} className='text-start px-5 col-8'>
      Gastos
    </th>,

    <th key={3} className='px-2 text-center'>
      Estado
    </th>,

    <th key={4} className='text-end'></th>,
  ];

  //////// BODY TABLE ////////////
  const bodyTable = array => {
    let tempList = [];
    if (Array.isArray(array)) {
      array.forEach((item, index) => {
        tempList.push(
          <tr key={index} className={`hover-table-row`}>
            <td className='px-2 text-center'>{item.id}</td>
            <td className='px-5 text-start'>{item.name}</td>
            <td className='px-2 text-center'>
              <div
                className={treasuryStyles.cardStatus}
                style={{ color: item.status.fontColor, background: item.status.bgColor }}
              >
                {item.status.name}
              </div>
            </td>
            <td className='px-2 text-end'>
              <CustomPopupExtend
                noHover
                showEnable
                isEnabled={item.status.value === 'enabled'}
                position='right'
                triggerSrc={ThreeDots}
                enableClickEvent={() => {
                  customSwaltAlert({
                    icon: 'warning',
                    title: '¿Está seguro?',
                    text: `Se ${item.status.value === 'enabled' ? 'deshabilitará' : 'habilitará'} el tipo de gasto: ${item.name}`,
                    confirmButtonText: 'Si, continuar',
                    cancelButtonText: 'Cancelar',
                    showCancelButton: true,
                  }).then(result => {
                    if (result.isConfirmed) {
                      onChangeStatus(item);
                    }
                  });
                }}
              />
            </td>
          </tr>,
        );
      });
    }
    return tempList;
  };

  const renderFilters = () => {
    return (
      <div>
        <h1>Tipos de gastos con TC</h1>
        <div className={treasuryStyles.filtersGrid}>
          <div>
            <div>Gastos</div>
            <Select
              styles={ordCustomSelect}
              className='text-secondary '
              placeholder={'Seleccionar...'}
              options={listTypesCostsTcSelect}
              noOptionsMessage={() => 'No hay datos'}
              onChange={el => {
                getListTypesCostTc({
                  token,
                  url: '/cash/expense_types',
                  objFilters: { ...filters, id: el.value, page: 1 },
                });

                setFilters(state => ({
                  ...state,
                  page: 1,
                  id: el.value,
                }));
              }}
            />
          </div>
          <div>
            <div>Estado</div>
            <Select
              styles={ordCustomSelect}
              className='text-secondary '
              placeholder={'Seleccionar...'}
              options={listStatusSelect}
              noOptionsMessage={() => 'No hay datos'}
              onChange={el => {
                getListTypesCostTc({
                  token,
                  url: '/cash/expense_types',
                  objFilters: { ...filters, status: el.value, page: 1 },
                });

                setFilters(state => ({
                  ...state,
                  page: 1,
                  status: el.value,
                }));
              }}
            />
          </div>
          <div></div>

          <button
            type='button'
            style={{ justifySelf: 'end', columnGap: 10 }}
            className={treasuryStyles.btnPrimaryFilters}
            onClick={handleShowConfig}
          >
            <span>Modificar tipos de gastos</span>
            <AddCircleOutline stroke={3} fontSize='small' />
          </button>
        </div>
      </div>
    );
  };

  const renderTable = () => {
    return (
      <GenericTableNew dark={true} headers={headerTable}>
        {bodyTable(listTypesCostsTc?.data || [])}
      </GenericTableNew>
    );
  };

  const main = () => {
    return (
      <>
        {(loaderListTypesCostsTc || loaderTypesCostsTc || loadSendDataForm) && loader}

        <div className={treasuryStyles.container}>
          {renderFilters()}
          {renderTable()}

          <div className={paginationStyles.wrapper}>
            <p className={paginationStyles.paginationText}>
              Pag. {filters.page}
              {' de '}
              {Math.ceil(listTypesCostsTc?.rowTotal / filters.perpage)
                ? Math.ceil(listTypesCostsTc?.rowTotal / filters.perpage)
                : '1'}{' '}
              ({listTypesCostsTc?.rowTotal} encontrados)
            </p>
            <Pagination
              activePage={filters.page}
              itemsCountPerPage={filters.perpage}
              totalItemsCount={listTypesCostsTc?.rowTotal}
              pageRangeDisplayed={5}
              onChange={page => {
                setFilters({ ...filters, page: page });

                getListTypesCostTc({
                  token,
                  url: '/cash/expense_types',
                  objFilters: { ...filters, page: page },
                });
              }}
              itemClassPrev={paginationStyles.itemClassPrev}
              itemClassNext={paginationStyles.itemClassNext}
              itemClassFirst={paginationStyles.itemClassFirst}
              itemClassLast={paginationStyles.itemClassLast}
              itemClass={paginationStyles.itemClass}
            />
          </div>

          <TypesCostsTcConfig
            config={config}
            handleClose={handleCloseConfig}
            onUpdateSuccess={onUpdateSuccess}
          />
        </div>
      </>
    );
  };

  return main();
};
