import { Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Pagination from 'react-js-pagination';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import 'reactjs-popup/dist/index.css';
import Select from 'react-select';
import { customSelectNewDark } from '../../Layouts/react-select-custom';

import checkIcon from '../../../assets/img/icons/CheckMark.svg';
import detail from '../../../assets/img/icons/Filtrar.svg';
import approvedIcon from '../../../assets/img/icons/check2.svg';
import REJECTED from '../../../assets/img/icons/extraBtnRedX.svg';
import ADVANCE from '../../../assets/img/icons/ReembolsoDarkBlue.svg';
import Search from '../../../assets/img/icons/lupa.svg';
import threeDots from '../../../assets/img/icons/threeDots.svg';

import { useGetMethod, usePostMethod } from '../../../Hooks/useFetch';
import { getVacactionsList } from '../../../actions/approvedNewsActions';
import { costcenter_get } from '../../../actions/costcenterActions';
import { customSwaltAlert } from '../../../helpers/customSwaltAlert';
import { convertMoneyFormat, isEmptyOrUndefined, loader, message } from '../../../helpers/helpers';

import { Title } from '../../../shared';
import GenericTableNew from '../../Layouts/GenericTableNew';
import ModalNew from '../../Layouts/ModalNew';
import { PdfViewer } from '../../Layouts/PdfViewer';
import CustomPopupExtend from '../../Popup/customPopUpExtends';

import paginationStyles from '../../Layouts/pagination.module.scss';
import tableStyles from '../../Layouts/tableStyle.module.scss';
import '../../TableUsers/tableUserStyle.scss';

export const VacationsAuthorizations = () => {
  const store = useSelector(state => state);
  const dispatch = useDispatch();
  const history = useHistory();

  const myPermission = store.loginReducer.currentAccount?.profile?.permission?.find(
    x => x.functionality?.prefix === 'vctns',
  );
  const idEnterprise = store.loginReducer.currentAccount.id;
  const token = store.loginReducer.Authorization;
  const arrVacations = store.approvedNewsReducer?.vacationList;

  const [commentModal, setCommentModal] = useState({
    show: false,
    comment: '',
    userName: '',
  });
  const [settlement, setSettlement] = useState({
    show: false,
    base64: '',
    title: '',
  });
  const [trigger, setTrigger] = useState(0);
  const [filters, setFilters] = useState({
    page: 1,
    perpage: 10,
    entity_account: idEnterprise,
    search: '',
    status: 'approved,processed,advance',
    dateFrom: '',
    dateUntil: '',
    area: '',
  });

  const statusOptions = [
    { value: 'approved,processed,advance', label: 'Seleccionar...' },
    { value: 'advance', label: 'Anticipo' },
    { value: 'approved', label: 'Aprobado' },
    { value: 'processed', label: 'Procesado' },
  ];

  const { load: sendRequestLoader, trigger: sendRequestMethod } = usePostMethod();
  const { load: settlementLoader, trigger: getSettlementPdfMethod } = useGetMethod();

  useEffect(() => {
    if (!myPermission?.read) {
      history.push('/nomina/inicio');
    }

    dispatch(
      costcenter_get({
        eaccount: store.loginReducer.currentAccount.id,
        active: 1,
      }),
    );
    dispatch(getVacactionsList(filters));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.page, trigger]);

  const getSettlementPdf = (id, title) => {
    getSettlementPdfMethod({
      url: '/payroll/vacationSettlementPdf/',
      objFilters: {
        eaccount: idEnterprise,
        vacationId: id,
      },
      token: token,
      doAfterSuccess: res => {
        setSettlement({ ...settlement, show: true, base64: res.results.base64, title: title });
      },
    });
  };
  const renderElement = elem => {
    return (
      <tr key={elem.id}>
        <td className='col-md-2 text-start px-3'>{elem?.collaborator ?? '-'}</td>
        <td className='col-md-1 text-start'>{elem.type === 'paid' ? 'Pagadas' : 'Descansadas'}</td>
        <td className='col-md-2 text-center'>
          {!isEmptyOrUndefined(elem.count) ? elem.count + ' día(s)' : '-'}
        </td>
        <td className='col-md-2 text-center'>{elem?.date ?? '-'}</td>
        <td className='col-md-2 text-center'>&nbsp; {elem.period ?? '-'}</td>
        <td className='col-md-2 text-end'>
          {convertMoneyFormat(Number(elem?.paymentValue ?? '0').toFixed(2))}
        </td>
        <td className='col-md-1 text-center'>
          {
            <Tooltip title={
              elem.status === 'processed'
                ? 'Procesado'
                : elem.status === 'approved'
                  ? 'Aprobado'
                  : elem.status === 'advance'
                  && 'Anticipo'
            } arrow>
              <img
                alt='img'
                src={
                  elem.status === 'approved'
                    ? checkIcon
                    : elem.status === 'processed'
                      ? approvedIcon
                      : elem.status === 'advance'
                      && ADVANCE
                }
              />
            </Tooltip>
          }
        </td>
        <td className='col-md-1 text-center cursorPointer'>
          <div>
            <CustomPopupExtend
              noHover
              triggerSrc={threeDots}
              extraButtons={[
                {
                  text: 'Rechazar',
                  icon: REJECTED,
                  event:
                    elem.status === 'approved'
                      ? () =>
                        setCommentModal({
                          ...commentModal,
                          show: true,
                          userName: elem?.collaborator,
                          idVacation: elem.id,
                        })
                      : null,
                },
                {
                  text: 'Ver liquidación',
                  icon: detail,
                  event: () => getSettlementPdf(elem.id, 'Ver liquidación')
                },
              ]}
            />
          </div>
        </td>
      </tr>
    );
  };

  const handleDeny = (name, method) => {
    let messageText = `Se rechazará la solicitud de vacaciones de ${name}`;
    if (!isEmptyOrUndefined(commentModal.comment)) {
      messageSwal(messageText, method, name);
    } else {
      return message(
        'info',
        'Campos vacíos',
        'Para rechazar las vacaciones debe enviar un comentario',
        undefined,
        true,
      );
    }
  };
  const messageSwal = (message, method, name, id) => {
    customSwaltAlert({
      icon: 'warning',
      title: '¿Está seguro?',
      text: message,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Si, Continuar',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      showCancelButton: true,
    }).then(result => {
      if (result.isConfirmed) {
        sendRequestAction(method, name, id);
      }
    });
  };
  const sendRequestAction = (method, name, id) => {
    let messageText =
      method === 'rejected'
        ? `Se ha rechazado la solicitud de vacaciones de ${name}`
        : `Se ha procesado la solicitud de vacaciones de ${name}`;
    let titleText =
      method === 'rejected' ? `Solicitud rechazada con éxito` : `Solicitud procesada con éxito`;
    sendRequestMethod({
      url: `/payroll/vacations/${id ?? commentModal?.idVacation}/`,
      token: token,
      method: 'PUT',
      body: {
        comment: commentModal.comment,
        status: method,
      },
      succesAction: () => {
        closeCommentModal();
        setTrigger(trigger + 1);
        message('success', titleText, messageText, undefined, true);
      },
    });
  };
  const listElem = () => {
    let elemMap;
    if (arrVacations !== undefined) {
      const elem2 = arrVacations;
      if (elem2.length > 0) {
        elemMap = elem2.map(elem => {
          return renderElement(elem);
        });
      }
    }
    return elemMap;
  };
  const renderHeaders = [
    <th key={1} className='text-start px-3'>
      Solicitante
    </th>,
    <th key={2} className='text-start'>
      Tipo
    </th>,
    <th key={4} className='text-center'>
      Solicitud
    </th>,
    <th key={5} className='text-center'>
      Fechas solicitadas
    </th>,
    <th key={3} className='text-center'>
      Periodo de vacaciones
    </th>,
    <th key={6} className='text-end'>
      Valor a pagar
    </th>,
    <th key={7} className='text-center'>
      Estado
    </th>,
    <th key={8}>&nbsp;</th>,
  ];
  const handleSearch = e => {
    e.preventDefault();
    setFilters({ ...filters, page: 1 });
    setTrigger(trigger + 1);
  };
  const closeCommentModal = () => {
    setCommentModal({
      ...commentModal,
      show: false,
      comment: '',
      userName: '',
      idVacation: '',
    });
  };
  const closeSettlementModal = () => {
    setSettlement({
      ...settlement,
      show: false,
      base64: '',
    });
  };

  return (
    <>
      {(store.approvedNewsReducer?.loadingVct || settlementLoader || sendRequestLoader) && loader}
      <ModalNew
        title={settlement.title}
        show={settlement.show}
        btnNoName={'Cerrar'}
        size='940'
        btnYesEvent={null}
        onHide={() => closeSettlementModal()}
        btnNoEvent={() => closeSettlementModal()}
        btnNoDisabled={false}
      >
        <div>
          <PdfViewer
            downloadable
            pdfWidth={790}
            containerClass={`${tableStyles.w54}`}
            file={`data:application/pdf;base64,${settlement.base64?.split("'")[1]}`}
          ></PdfViewer>
        </div>
      </ModalNew>
      <ModalNew
        title='Comentario'
        show={commentModal.show}
        btnNoName={'Cerrar'}
        size='400'
        btnYesEvent={() => handleDeny(commentModal.userName, 'rejected')}
        onHide={() => closeCommentModal()}
        btnNoEvent={() => closeCommentModal()}
        btnNoDisabled={false}
      >
        <p className={tableStyles.crudModalLabel}>Comentario</p>
        <textarea
          placeholder={'Escribir...'}
          id='txtArea'
          rows='10'
          cols='10'
          style={{
            height: '4rem',
            borderRadius: '5px',
            border: '1.5px solid #01A0F6',
            padding: '0 0.05rem',
            width: '100%',
          }}
          onChange={e => {
            setCommentModal({ ...commentModal, comment: e.target.value });
          }}
          className={` text-secondary mt-1 ${tableStyles.outlineNone}`}
        ></textarea>
      </ModalNew>
      <div className={tableStyles.container}>
        <div className={tableStyles.tableArea}>
          <div className={tableStyles.title}>
            <Title
              title='Revisión de vacaciones'
              onClickIcon={() => {
                history.push('/contabilidad/inicio');
              }}
            />
          </div>
          <Row>
            <Col xs={2}>
              <p className={`${tableStyles.crudModalLabel} `}>Estado</p>
              <Select
                noOptionsMessage={() => 'No hay datos'}
                options={statusOptions}
                className='text-secondary '
                placeholder={'Seleccione'}
                styles={customSelectNewDark}
                onChange={(e) => {
                  setFilters({ ...filters, status: e.value })
                  setTrigger(trigger + 1);
                }}
                value={statusOptions.find(x => x.value === filters.status)}
              />
            </Col>
            <Col xs={2}>
              <p className={`${tableStyles.crudModalLabel} `}>Fecha desde</p>
              <input
                placeholder='Escribe...'
                className='register-inputs text-primary  fw-bold'
                type='date'
                onChange={e => setFilters({ ...filters, dateFrom: e.target.value })}
              />
            </Col>
            <Col xs={2}>
              <p className={`${tableStyles.crudModalLabel} `}>Hasta</p>
              <input
                placeholder='Escribe...'
                className='register-inputs text-primary  fw-bold'
                type='date'
                onChange={e => setFilters({ ...filters, dateUntil: e.target.value })}
              />
            </Col>

            <Col className='d-block' xs={4}>
              <div className='pt-4'>
                <form onSubmit={e => handleSearch(e)} className='d-flex'>
                  <input
                    className={`${tableStyles.SearchNew} mr-2 text-secondary`}
                    onChange={e => setFilters({ ...filters, search: e.target.value })}
                    name='search'
                    type='text'
                    placeholder='Buscar...'
                  />
                  <img
                    src={Search}
                    className='cursorPointer'
                    width={'24px'}
                    height='24px'
                    style={{ marginBottom: '2px' }}
                    alt='Search icon'
                    onClick={e => handleSearch(e)}
                  />
                </form>
              </div>
            </Col>
          </Row>

          <GenericTableNew headers={renderHeaders} dark={true}>
            {listElem()}
          </GenericTableNew>
          <div className={paginationStyles.wrapper}>
            <p className={`${paginationStyles.paginationText} text-secondary`}>
              Pag. {store.approvedNewsReducer.vacationList ? filters.page : ''}
              {' de '}
              {Math.ceil(store.approvedNewsReducer.rowTotal / filters.perpage)
                ? Math.ceil(store.approvedNewsReducer.rowTotal / filters.perpage)
                : ''}{' '}
              ({store.approvedNewsReducer.rowTotal} encontrados)
            </p>
            <Pagination
              activePage={filters.page}
              itemsCountPerPage={filters.perpage}
              totalItemsCount={store.approvedNewsReducer.rowTotal}
              pageRangeDisplayed={5}
              onChange={e => setFilters({ ...filters, page: e })}
              itemClassPrev={paginationStyles.itemClassPrev}
              itemClassNext={paginationStyles.itemClassNext}
              itemClassFirst={paginationStyles.itemClassFirst}
              itemClassLast={paginationStyles.itemClassLast}
              itemClass={paginationStyles.itemClass}
            />
          </div>
        </div>
      </div>
    </>
  );
};
