import moment from 'moment';
import Loader from 'react-loader-spinner';
import Swal from 'sweetalert2';
import '../OrderingModule/orderingStyles.css';
import Image from '../assets/img/icons/Imagen.svg';
import Excel from '../assets/img/icons/excel.svg';
import PDF from '../assets/img/icons/pdf.svg';
import File from '../assets/img/icons/subir.svg';
import Word from '../assets/img/icons/word.svg';
import { API_VERSION, URL_GATEWAY } from './constants';
import { convertFilterToString } from './convertToFilter';
import { customSwaltAlert } from './customSwaltAlert';
import { customSwaltAlertAsistencial } from './customSwaltAlertAsistencial';
import { getEnvs } from "./getEnvs";

export function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}
export const calculatePresentationMedicine = medicine => {
  let mdcQtyDosis = parseFloat(medicine?.mdcQtyDosis);
  let mcdQtyFrequency = parseFloat(medicine?.mcdQtyFrequency);
  let frequencyValue = parseFloat(medicine?.frequencyValue);
  let mcdQtyPeriod = parseFloat(medicine?.mcdQtyPeriod);
  let periodValue = parseFloat(medicine?.periodValue);

  let qtyPerMeasUnit = parseFloat(medicine?.qtyPerMeasUnit);
  let qtyPerPresentation = parseFloat(medicine?.qtyPerPresentation);
  let result = Math.ceil(
    (mdcQtyDosis * ((mcdQtyPeriod * periodValue) / (mcdQtyFrequency * frequencyValue))) /
    (qtyPerPresentation / qtyPerMeasUnit),
  );
  return {
    medicine: medicine,
    result: result,
    presentation: medicine?.presentationDescrption,
  };
};

export const convertMoneyFormat = (textNumber, symbolDecimal = ',') => {
  if (!isEmptyOrUndefined(textNumber)) {
    var num_parts = textNumber.toString().split('.');
    num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    if (num_parts.length === 1) {
      num_parts.push('00');
    } else {
      num_parts[1] = num_parts[1].padEnd(2, '0').substring(0, 2);
    }
    return `$${num_parts.join(symbolDecimal)}`;
  }
  return '';
};
export const convertMoneyFormatNew = ({
  textNumber,
  SymbolDecimal = ',',
  SymbolShow = false,
  convertNumber,
}) => {
  if (!isEmptyOrUndefined(textNumber)) {
    if (convertNumber) {
      let text = textNumber?.replaceAll('.', '')?.replaceAll(',', '')?.replaceAll('$', '');
      return Number(text);
    }
    var num_parts = textNumber.toString().split('.');
    num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, SymbolDecimal);
    return `${SymbolShow ? '' : '$'}${num_parts.join(SymbolDecimal)}`;
  }
};

export const getPaymentCondition = pay => {
  switch (pay) {
    case 'prepayment':
      return 'Pago anticipado';
    case 'delivery':
      return 'Pago contraentrega';
    case 'pay20':
      return 'Pago a 20 días';
    case 'pay30':
      return 'Pago a 30 días';
    case 'pay60':
      return 'Pago a 60 días';
    case 'pay90':
      return 'Pago a 90 días';
    case null:
      return '-';
    default:
      return pay;
  }
};

export const removeSpaces = text => {
  return text.replace(/\s/g, '');
};

export const message = (icon, title, text, confirmButtonText, oldStyle, asistencialStyle) => {
  // styles
  if (asistencialStyle) {
    customSwaltAlertAsistencial({
      icon,
      title,
      text,
      confirmButtonText: 'Aceptar',
      showCancelButton: false,
    });
  } else if (oldStyle) {
    customSwaltAlert({
      icon: icon,
      title: title,
      text: text,
      confirmButtonText: 'Aceptar',
      showCancelButton: false,
    });
  } else {
    Swal.fire({
      icon,
      title,
      text,
      confirmButtonText: confirmButtonText || 'Aceptar',
      showCancelButton: false,
      // confirmButtonColor: styles?.confirmButtonColor || "blue",
      // cancelButtonColor: "#d33",
    });
  }
};

//THIS FUNCTION WORKS TO FORMAT ANY PETITION TO REACT SELECT WAY, THE FIRST PARAM IS AN ARRAY , SECOND AND THIRD ARE THE NAMES OF ID AND NAME FOR RC SELECT, THOSE SHOULD BE STRING
export const formatToRcSelect = (
  arrToFormat, //Original array
  idName, // Value
  valueName, // Label
  extra, // Simple extra value
  extra2, // Simple extra value
  concat, // The key you want to concat to the original label
  withEmptyValue = true, // with or without "-Seleccione-" initial value
  extra3, // an extra JSon to take more params
  extra4, // an extra JSon to take more params
  extra5, // an extra JSon to take more params
  extra6, // an extra JSon to take more params
  extra7, // an extra JSon to take more params
  extra8, // an extra JSon to take more params
  extra9, // an extra JSon to take more params
  extra10, // an extra JSon to take more params
  concatValue, // an extra concat to the value
  concat2, // an extra concat string to the label
) => {
  let arrOut = withEmptyValue
    ? [
      {
        label: 'Seleccionar...',
        value: '',
        extra: '',
      },
    ]
    : [];

  if (Array.isArray(arrToFormat)) {
    if (arrToFormat.length > 0) {
      // if (arrToFormat.length >= 100) {
      //   let fiveHundredArray = arrToFormat.slice(0, 10);
      //   // //console.log(
      //   //   "🚀 ~ file: helpers.js ~ line 84 ~ fiveHundredArray",
      //   //   fiveHundredArray
      //   // );
      //   fiveHundredArray.forEach((e) => {
      //     arrOut.push({
      //       label: concat ? e[valueName] + " - " + e[concat] : e[valueName],
      //       value: e[idName],
      //       extra: e[extra],
      //       extra2: e[extra2],
      //     });
      //   });
      // } else {
      arrToFormat.forEach(e => {
        arrOut.push({
          label: concat2
            ? e[valueName] + ' - ' + e[concat] + concat2
            : concat
              ? e[valueName] + ' - ' + e[concat]
              : e[valueName],
          // value: e[idName],
          value: concatValue ? String(e[idName]) + String(e[concatValue]) : e[idName],
          extra: e[extra] ?? extra,
          extra2: e[extra2],
          extra3: e[extra3],
          extra4: e[extra4],
          extra5: e[extra5],
          extra6: e[extra6],
          extra7: e[extra7],
          extra8: e[extra8],
          extra9: e[extra9],
          extra10: e[extra10],
        });
      });
      // }
    }
  }

  return arrOut;
};

export const convertBase64 = file => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.onerror = error => {
      reject(error);
    };
  });
};

export const accTypes = [
  { value: '', label: 'Seleccionar...' },
  { value: 'C', label: 'Crédito' },
  { value: 'D', label: 'Debito' },
];
export const rhFactor = [
  { value: '', label: 'Seleccionar...' },
  { value: 'N', label: 'Negativo' },
  { value: 'P', label: 'Positivo' },
];

export const placeMyIcon = iconExtension => {
  if (!isEmptyOrUndefined(iconExtension)) {
    switch (iconExtension) {
      case 'pdf':
        return PDF;
      case 'word':
        return Word;
      case 'xls':
        return Excel;
      case 'jpg' || 'jpeg' || 'png':
        return Image;
      case 'zip' || 'rar':
        return File;
      default:
        break;
    }
  }
};
//This function take two params
//1: value=any => is the value to test its respective regExp
//2: regExp=String => is the respective regular expression for value both are mandatory
export const validExpReg = (value, regExp) => {
  let reg = new RegExp(regExp);
  if (reg.test(value)) {
    //IF TRUE => VALUE IS A VALID DATA TYPE
    return true;
  } else {
    //ELSE IS ISN'T A VALID DATA TYPE
    return false;
  }
};

export const convertDateToLatinFormat = date => {
  return moment(date, 'DD/MM/YYYY').format('DD/MM/YYYY');
};

// function to validate a email structure

//This object is a list of keys that recevies a string of a regular expression
export const expRegList = {
  string:
    /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u,
  number: '[0-9]$',
  email:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  onlyPositiveNumber: '^d$|^[1][0]$',
};
export const isEmptyOrUndefined = value => {
  if (
    value === undefined ||
    value === null ||
    value === '' ||
    // eslint-disable-next-line use-isnan
    value === NaN ||
    value === -1
  ) {
    return true;
  } else {
    return false;
  }
};

export const getFilenameByURL = url => {
  var filename = url.substring(url.lastIndexOf('/') + 1);
  return filename;
};

//THIS PIECE OF CODE EXPORT THE TODAY'S DAY, IT WORKS TO VALID IF A DATE FIELD HAVE MAJOR VALUE THAN TODAY
// <input max/min={today} type="date" />;
export const today = () => {
  let today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; //January is 0!
  let yyyy = today.getFullYear();

  if (dd < 10) {
    dd = '0' + dd;
  }

  if (mm < 10) {
    mm = '0' + mm;
  }

  today = yyyy + '-' + mm + '-' + dd; //AAA-MM-DD
  return today;
};
export const firtsOfTheMonth = () => {
  const today = new Date();
  const dd = '01';
  let mm = today.getMonth() + 1; //January is 0!
  const yyyy = today.getFullYear();

  if (mm < 10) {
    mm = '0' + mm;
  }

  const firtsofthemonth = yyyy + '-' + mm + '-' + dd; //AAA-MM-DD
  return firtsofthemonth;
};
export const month = () => {
  let today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; //January is 0!

  if (dd < 10) {
    dd = '0' + dd;
  }

  if (mm < 10) {
    mm = '0' + mm;
  }

  return mm;
};
export const year = () => {
  let today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; //January is 0!
  let yyyy = today.getFullYear();

  if (dd < 10) {
    dd = '0' + dd;
  }

  if (mm < 10) {
    mm = '0' + mm;
  }

  return yyyy;
};
export const day = () => {
  let today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; //January is 0!

  if (dd < 10) {
    dd = '0' + dd;
  }

  if (mm < 10) {
    mm = '0' + mm;
  }

  return dd;
};

export const numberWithCommas = x => {
  if (!isEmptyOrUndefined(x)) {
    var parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return parts.join('.');
  }
};

export const calculateDays = (dateStart, dateEnd) => {
  let ellapsedDays;
  if (!isEmptyOrUndefined(dateStart) && !isEmptyOrUndefined(dateEnd)) {
    let miliSecondsDay = 24 * 60 * 60 * 1000;
    let elapsedMiliSecods = Math.abs(dateStart?.getTime() - dateEnd?.getTime());
    let ellapsedDays = Math.round(elapsedMiliSecods / miliSecondsDay);

    return ellapsedDays;
  }
  return ellapsedDays;
};

export const downloaFile = (file, fileName = "vct_illustration.pdf") => {
  // //console.log(file, "file")
  let treatPdf = file.replace(/^b'/gm, '').replace(/=*'$/gm, '');
  //^b'[A-Za-z0-9+_\/]*=*'$
  let linkSource = `data:application/pdf;base64,${treatPdf}`;
  let downloadLink = document.createElement('a');
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
};

export const downloadFilePdf = (file, filename) => {
  // //console.log(file, "file")
  let treatPdf = file.replace(/^b'/gm, '').replace(/=*'$/gm, '');
  //^b'[A-Za-z0-9+_\/]*=*'$
  let linkSource = `data:application/pdf;base64,${treatPdf}`;
  let downloadLink = document.createElement('a');
  downloadLink.href = linkSource;
  downloadLink.download = filename;
  downloadLink.click();
};
const { APP_LANGUAGE, CURRENCY } = getEnvs();

export const formatter = new Intl.NumberFormat(APP_LANGUAGE, {
  style: 'currency',
  currency: CURRENCY,
});

export const daysOfTheMonth = [
  { value: '', label: '-Seleccione-' },
  { value: 1, label: '01' },
  { value: 2, label: '02' },
  { value: 3, label: '03' },
  { value: 4, label: '04' },
  { value: 5, label: '05' },
  { value: 6, label: '06' },
  { value: 7, label: '07' },
  { value: 8, label: '08' },
  { value: 9, label: '09' },
  { value: 10, label: '10' },
  { value: 11, label: '11' },
  { value: 12, label: '12' },
  { value: 13, label: '13' },
  { value: 14, label: '14' },
  { value: 15, label: '15' },
  { value: 16, label: '16' },
  { value: 17, label: '17' },
  { value: 18, label: '18' },
  { value: 19, label: '19' },
  { value: 20, label: '20' },
  { value: 21, label: '21' },
  { value: 22, label: '22' },
  { value: 23, label: '23' },
  { value: 24, label: '24' },
  { value: 25, label: '25' },
  { value: 26, label: '26' },
  { value: 27, label: '27' },
  { value: 28, label: '28' },
  { value: 29, label: '29' },
  { value: 30, label: '30' },
  { value: 31, label: '31' },
];
export const monthsOfTheYear = [
  { value: '', label: '-Seleccione-' },
  { value: 1, label: '01' },
  { value: 2, label: '02' },
  { value: 3, label: '03' },
  { value: 4, label: '04' },
  { value: 5, label: '05' },
  { value: 6, label: '06' },
  { value: 7, label: '07' },
  { value: 8, label: '08' },
  { value: 9, label: '09' },
  { value: 10, label: '10' },
  { value: 11, label: '11' },
  { value: 12, label: '12' },
];

//TODO: ENHANCE
export const getFivePreviousYear = () => {
  let array = [];
  let current_year = year();
  for (let step = 0; step <= 5; step++) {
    array.push(current_year - step);
  }
  return array;
};

export const fetchGet = async params => {
  /* --------------------------- How to use example --------------------------- */
  // fetchGet({
  //   url: "/medical/medicine/get/",
  //   objFilters: { slim: 1, idAccount: 31 },
  //   token: token,
  // });
  let results = <>Algún spinner</>;
  let { url, objFilters, token } = params;
  const filters = convertFilterToString(objFilters);
  if (isEmptyOrUndefined(url)) {
    return message(
      'warning',
      'Parámetro url no encontrado',
      'El parámetro url es obligatorio en el objeto de configuración',
    );
  }
  if (isEmptyOrUndefined(token)) {
    return message(
      'warning',
      'Parámetro token no encontrado',
      'El parámetro token es obligatorio en el objeto de configuración',
    );
  }

  try {
    const query = await fetch(`${URL_GATEWAY}${API_VERSION}${url}?${filters}`, {
      method: 'GET',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
    });
    results = await query.json();
    //console.log("From inside", await results);
    return results;
  } catch (error) {
    console.error(error.message);
    return message('error', 'Error', 'Ha ocurrido un error');
  }
};

export const swalConfirm = props => {
  // const btn1 = `<button class="ordBtnPrimary">Hola mundo</button>`;
  let { title, text, icon, showCancelButton, confirmButtonText, cancelButtonText, doAfterConfirm, doAfterCancel } =
    props;
  Swal.fire({
    title: title || `¿Está seguro?`,
    text: text || 'Verifique la información',
    icon: icon || 'warning',
    customClass: {
      confirmButton: `ordBtnPrimary2`,
      cancelButton: `ordBtnSecondary`,
      title: `text-info`,
    },
    showCancelButton: showCancelButton,
    // confirmButtonColor: "#003f80",
    // cancelButtonColor: "#00b4cc",
    confirmButtonText: confirmButtonText || 'Si, Guardar',
    // confirmButtonText: btn1,
    cancelButtonText: cancelButtonText || 'Cancelar',
  }).then(result => {
    if (result.isConfirmed) {
      doAfterConfirm();
    } else {
      if (doAfterCancel) {
        doAfterCancel();
      }
    }
  });
};
export const loader = (
  <div
    style={{ zIndex: '2000', width: '100%', height: '100vh', position: 'fixed' }}
    className='loading'
  >
    <Loader type='Oval' color='#003f80' height={100} width={100} />
  </div>
);

export const validateEmptyString = (arrayString, care = false) => {
  /* --------------------------- HOW TO USE EXAMPLE --------------------------- */

  let len = arrayString.length;
  let error = 0;
  let isOk = 0;
  let valid = false;

  if (arrayString?.length > 0) {
    arrayString.forEach(e => {
      if (isEmptyOrUndefined(e.field)) {
        error = error + 1;
        if (!e.whithOutAlert) {
          if (care) {
            return customSwaltAlertAsistencial({
              icon: e.icon || 'warning',
              title: e.title || 'Intenta de nuevo',
              text: e?.text || e?.message || `Por favor llene todos los campos obligatorios`,
              confirmButtonText: 'Aceptar',
              showCancelButton: false,
            });
          }
          return customSwaltAlert({
            icon: e.icon || 'warning',
            title: e.title || 'Intenta de nuevo',
            text: e?.text || e?.message || `Por favor llene todos los campos obligatorios`,
            confirmButtonText: 'Aceptar',
            showCancelButton: false,
          });
        }
      } else {
        isOk = isOk + 1;
      }
    });
  }

  if (isOk === len) {
    valid = true;
  } else {
    valid = false;
  }

  return { valid };
};

export const downloadBase64 = url => {
  let win = window.open();
  win.document.write(
    '<iframe src="' +
    url +
    '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>',
  );
  setTimeout(() => {
    win.close();
  }, 3000);
};

export const getAge = birth_date => {
  let age = moment(birth_date);
  let today = moment();
  let years = today.diff(age, 'years');
  return years;
};

export const capitalizeWord = word => {
  let capitalized = word?.charAt(0).toUpperCase() + word?.slice(1);
  return capitalized;
};

export const imgNoExist = 'https://dummyimage.com/16:9x1080/';

export const generateId = (limit = 2) => {
  const random = Math.random().toString(36).substr(limit);
  const date = Date.now().toString(36);
  return random + date;
};
export const genericDownloaFile = (file, filename) => {
  let treatPdf = file.replace(/^b'/gm, '').replace(/=*'$/gm, '');
  //^b'[A-Za-z0-9+_\/]*=*'$
  let linkSource = treatPdf;
  let downloadLink = document.createElement('a');
  // let fileName = "vct_illustration.pdf";
  downloadLink.href = linkSource;
  downloadLink.download = filename;
  downloadLink.click();
};

export const genericDownloadExcel = (base64Data, filename) => {
  const treatedBase64 = base64Data.replace(/^data:.*;base64,/, '');

  const byteCharacters = atob(treatedBase64);
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);

  const blob = new Blob([byteArray], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });

  const downloadLink = document.createElement('a');

  const url = URL.createObjectURL(blob);

  downloadLink.href = url;
  downloadLink.download = filename;

  downloadLink.click();
  URL.revokeObjectURL(url);
};

export const downloaFileAddName = (file, name) => {
  let treatPdf = file.replace(/^b'/gm, '').replace(/=*'$/gm, '');
  //^b'[A-Za-z0-9+_\/]*=*'$
  let linkSource = `data:application/pdf;base64,${treatPdf}`;
  let downloadLink = document.createElement('a');
  let fileName = name.length > 0 ? `${name}.pdf` : 'vct_illustration.pdf';
  // let fileName = "vct_illustration.pdf";
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
};
export const downloadExcel = (file, name, fileExtend) => {
  // //console.log(file, "file")
  let treatPdf = file.replace(/^b'/gm, '').replace(/=*'$/gm, '');
  //^b'[A-Za-z0-9+_\/]*=*'$
  let linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${treatPdf}`;
  let downloadLink = document.createElement('a');
  let fileName = name.length > 0 ? `${name}.xlsx` : 'vct_illustration.xlsx';
  downloadLink.href = fileExtend ? file : linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
};

export function convertInputToDate(idInput) {
  let currentType = document.getElementById(idInput);
  currentType.type = 'date';
}
export function convertInputToText(idInput) {
  let currentType = document.getElementById(idInput);
  currentType.type = 'text';
}

export function checkArrayEquality(_array1, _array2) {
  return (
    _array1.length === _array2.length &&
    _array1.every(function (_array1_i, i) {
      return _array1_i === _array2[i];
    })
  );
}

export const downloadImg = (file, name) => {
  // let file = convertToBase64Replace(fileNew)
  // //console.log("file: ", file);
  let treatPdf = file.replace(/^b'/gm, '').replace(/=*'$/gm, '');
  //^b'[A-Za-z0-9+_\/]*=*'$
  let linkSource = `data:image/png;base64,${treatPdf}`;
  //console.log("linkSource: ", linkSource);
  let downloadLink = document.createElement('a');
  //console.log("downloadLink: ", downloadLink);
  let fileName = name.length > 0 ? `${name}.jpg` : 'vct_illustration.png';
  // let fileName = "vct_illustration.pdf";
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
};

export const optionsSelect = ({ list = [], label, value, extra = '' }) => {
  let newList = [];
  const defaultSelect = { key: 'default', value: '', label: 'Seleccionar...', extra: extra };
  if (list?.length) {
    newList = [defaultSelect, ...list];
  }

  return newList?.map((e, i) =>
    i === 0
      ? { key: 'default', label: e.label, value: e.value, extra: extra }
      : { key: generateId(), label: e[label], value: e[value], extra: extra, ...e },
  );
};

export const valueSelect = ({ list = [], findId, findLabel, value }) => {
  // eslint-disable-next-line eqeqeq
  const objProvince = list?.find(e => e[findId] == value) ?? {};
  const result = value
    ? { label: objProvince[findLabel], value: objProvince[findId], key: generateId() }
    : '';
  return result;
};

export const getPermission = ({ prefix, listPermission }) => {
  if (Array.isArray(listPermission)) {
    const results = listPermission?.find(x => x.functionality?.prefix === prefix);
    return results;
  }

  return listPermission;
};

export const onCapitalLetter = ({ text }) => {
  const formatText = text.toLowerCase()?.split('');
  formatText[0] = formatText[0].toUpperCase();
  const textJoin = formatText.join('');

  return textJoin;
};

export const handleFilterSelectDynamic = async ({
  keyboardValue,
  listFilter,
  valueField,
  labelField,
  optionalLabelField,
  defaultValue = undefined,
}) => {
  let nombreEncontrados;
  let expresion = new RegExp(`${String(keyboardValue ?? defaultValue)?.toUpperCase()}.*`, 'i');
  nombreEncontrados = listFilter?.filter(
    dat => expresion?.test(dat[labelField]) || expresion?.test(dat[optionalLabelField]),
  );
  const results = optionsSelect({ list: nombreEncontrados, label: labelField, value: valueField });
  return results;
};

export const onChangeFile = async e => {
  var f = e.target.files[0];
  if (f) {
    var name_doc = f.name;
    var type_doc = f.type;
    var fileSize = f.size;

    let encode = await convertBase64(e.target.files[0]);
    let file64 = encode.split(',')[1];
    return {
      fileName: name_doc,
      file: file64,
      typeFile: type_doc.split('/')[1],
      fileSize,
    };
  }
};

export const removeDuplicates = ({ arrayObject, keyComparative }) => {
  let duplicates = [];
  let listClear = [];

  if (!arrayObject?.length) {
    return { success: false, listClear: [], duplicates: [] };
  }

  if (!Array(arrayObject)) {
    return { success: false };
  }

  for (let i = 0; i < arrayObject?.length; i++) {
    for (let j = 0; j < arrayObject?.length; j++) {
      if (i !== j && arrayObject[i][keyComparative] === arrayObject[j][keyComparative]) {
        let isExist = duplicates.filter(
          el => el[keyComparative] === arrayObject[i][keyComparative],
        )?.length;
        if (!isExist) {
          duplicates?.push(arrayObject[i]);
        }
      } else {
        let isExist = listClear.filter(
          el => el[keyComparative] === arrayObject[i][keyComparative],
        )?.length;
        if (!isExist) {
          listClear?.push(arrayObject[i]);
        }
      }
    }
  }

  return {
    success: true,
    duplicates,
    listClear,
  };
};

export const inputMuiGray = {
  '& label.Mui-focused': {
    color: '#A2A3AB',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#A2A3AB',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#A2A3AB',
    },
    '&:hover fieldset': {
      borderColor: '#A2A3AB',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#A2A3AB',
    },
  },
};

export const onlyCharacters = value => {
  let isValid = false;
  const pattern = new RegExp('^[A-ZÁÉÍÓÚÑ ]+$', 'i');
  if (pattern.test(value) || value === '') {
    isValid = true;
  }

  return isValid;
};

export function setCookie(name, value) {
  const valueString = JSON.stringify(value); // Convierte el valor a JSON

  document.cookie = `${name}=${valueString}; path=/`;
}

export function getCookie(name) {
  const cookieName = name + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(';');

  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i];
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(cookieName) === 0) {
      const cookieValue = cookie.substring(cookieName.length, cookie.length);
      try {
        return JSON.parse(cookieValue);
      } catch (error) {
        return cookieValue;
      }
    }
  }
  return null;
}
export function numberToWords(number) {
  function Unidades(num) {
    switch (num) {
      case 1:
        return 'UN';
      case 2:
        return 'DOS';
      case 3:
        return 'TRES';
      case 4:
        return 'CUATRO';
      case 5:
        return 'CINCO';
      case 6:
        return 'SEIS';
      case 7:
        return 'SIETE';
      case 8:
        return 'OCHO';
      case 9:
        return 'NUEVE';
      default:
        return 'INVALID';
    }
  }

  function Decenas(num) {
    const decena = Math.floor(num / 10);
    const unidad = num - decena * 10;

    switch (decena) {
      case 1:
        switch (unidad) {
          case 0:
            return 'DIEZ';
          case 1:
            return 'ONCE';
          case 2:
            return 'DOCE';
          case 3:
            return 'TRECE';
          case 4:
            return 'CATORCE';
          case 5:
            return 'QUINCE';
          default:
            return 'DIECI' + Unidades(unidad);
        }
      case 2:
        switch (unidad) {
          case 0:
            return 'VEINTE';
          default:
            return 'VEINTI' + Unidades(unidad);
        }
      case 3:
        return DecenasY('TREINTA', unidad);
      case 4:
        return DecenasY('CUARENTA', unidad);
      case 5:
        return DecenasY('CINCUENTA', unidad);
      case 6:
        return DecenasY('SESENTA', unidad);
      case 7:
        return DecenasY('SETENTA', unidad);
      case 8:
        return DecenasY('OCHENTA', unidad);
      case 9:
        return DecenasY('NOVENTA', unidad);
      case 0:
        return Unidades(unidad);
      default:
        return 'invalid';
    }
  }

  function DecenasY(strSin, numUnidades) {
    if (numUnidades > 0) return strSin + ' Y ' + Unidades(numUnidades);

    return strSin;
  }

  function Centenas(num) {
    const centenas = Math.floor(num / 100);
    const decenas = num - centenas * 100;

    switch (centenas) {
      case 1:
        if (decenas > 0) return 'CIENTO ' + Decenas(decenas);
        return 'CIEN';
      case 2:
        return 'DOSCIENTOS ' + Decenas(decenas);
      case 3:
        return 'TRESCIENTOS ' + Decenas(decenas);
      case 4:
        return 'CUATROCIENTOS ' + Decenas(decenas);
      case 5:
        return 'QUINIENTOS ' + Decenas(decenas);
      case 6:
        return 'SEISCIENTOS ' + Decenas(decenas);
      case 7:
        return 'SETECIENTOS ' + Decenas(decenas);
      case 8:
        return 'OCHOCIENTOS ' + Decenas(decenas);
      case 9:
        return 'NOVECIENTOS ' + Decenas(decenas);
      default:
        return Decenas(decenas);
    }
  }

  function Seccion(num, divisor, strSingular, strPlural) {
    const cientos = Math.floor(num / divisor);
    const resto = num - cientos * divisor;

    let letras = '';

    if (cientos > 0)
      if (cientos > 1) letras = Centenas(cientos) + ' ' + strPlural;
      else letras = strSingular;

    if (resto > 0) letras += '';

    return letras;
  }

  function Miles(num) {
    const divisor = 1000;
    const cientos = Math.floor(num / divisor);
    const resto = num - cientos * divisor;

    let strMiles = Seccion(num, divisor, 'UN MIL', 'MIL');
    let strCentenas = Centenas(resto);

    if (strMiles === '') return strCentenas;

    return strMiles + ' ' + strCentenas;
  } //Miles()

  function Millones(num) {
    const divisor = 1000000;
    const cientos = Math.floor(num / divisor);
    const resto = num - cientos * divisor;

    let strMillones = Seccion(num, divisor, 'UN MILLON', 'MILLONES');
    let strMiles = Miles(resto);

    if (strMillones === '') return strMiles;

    return strMillones + ' ' + strMiles;
  } //Millones()

  function NumeroALetras(num) {
    const data = {
      numero: num, // Assuming num is a variable holding some value
      enteros: Math.floor(num), // Assuming num is a variable holding some value
      centavos: Math.round(num * 100) - Math.floor(num) * 100, // Assuming num is a variable holding some value
      letrasMonedaPlural: 'PESOS COLOMBIANOS', // Assuming letrasMonedaPlural is a string
      letrasMonedaSingular: 'PESO COLOMBIANO', // Assuming letrasMonedaSingular is a string
    };

    if (data.enteros === 0) return 'CERO ' + data.letrasMonedaPlural;
    if (data.enteros === 1) return Millones(data.enteros) + ' ' + data.letrasMonedaSingular;
    else return Millones(data.enteros) + ' ' + data.letrasMonedaPlural;
  }

  return NumeroALetras(number);
}

export const downloadImage = (src) => {
  const img = new Image();
  img.crossOrigin = 'anonymous';
  img.src = src;
  img.onload = () => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = img.width;
    canvas.height = img.height;
    ctx.drawImage(img, 0, 0);
    const a = document.createElement('a');
    a.download = `${src.split('.').pop()}`;
    a.href = canvas.toDataURL('image/png');
    a.click();
  };
}

export function convertDateToISOFormat(dateString) {

  const [day, month, year] = dateString.split('/');

  return `${year}-${month}-${day}`;
}
