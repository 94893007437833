import Button from 'react-bootstrap/Button';

import Modal from 'react-bootstrap/Modal';

import treasuryStyles from '../bank-note-types.module.scss';

import close from '../../../../assets/img/icons/modalClose.svg';

import { ordCustomSelect } from '../../../../components/Layouts/react-select-custom';

import Select from 'react-select';

import { customSwaltAlert, isEmptyOrUndefined, loader } from '../../../../helpers';

import { useGetMethod, usePostMethod } from '../../../../Hooks';

import { memo, useEffect, useMemo, useState } from 'react';

export const BankNoteTypesConfig = memo(({ config, handleClose, onUpdateSuccess }) => {
  const title =
    config.action === 'UPDATE' ? 'Editar' : config.action === 'CREATE' ? 'Crear' : 'Detalle';

  const initialStateBankNoteTypes = useMemo(
    () => [
      { label: 'Débito', value: 'debit' },
      { label: 'Crédito', value: 'credit' },
    ],
    [],
  );

  const [data, setData] = useState({});

  const [listPuc, setListPuc] = useState([]);
  const [listPucDebit, setListPucDebit] = useState([]);

  const [bankNoteTypes, setBankNoteTypes] = useState(initialStateBankNoteTypes);

  const { load: loaderDetail, trigger: getDetail } = useGetMethod();

  const { load: loaderListPuc, trigger: getListPuc } = useGetMethod();

  const { load: loaderUpdate, trigger: onUpdate } = usePostMethod();

  const { load: loaderCreate, trigger: onCreate } = usePostMethod();

  const handleUpdate = () => {
    const isValidName = !isEmptyOrUndefined(data?.name);
    const isValidNoteType = !isEmptyOrUndefined(data?.type?.name || data?.type.id);
    const isValidAccount = !isEmptyOrUndefined(
      data?.type?.name === 'credit' ? data?.creditAccount?.id : data?.debitAccount?.id,
    );

    if (!isValidName || !isValidNoteType || !isValidAccount) {
      return customSwaltAlert({
        icon: 'warning',
        title: 'Intenta de nuevo',
        text: 'Todos los campos son obligatorios',
        showCancelButton: false,
      });
    }

    const body = {
      name: data.name,
      user: config.userId,
      noteType: data.type.name,
      eaccount: config.eaccount,
      account: data.type.name === 'credit' ? data?.creditAccount?.id : data?.debitAccount?.id,
      accountType:
        data.type.name === 'credit'
          ? data?.creditAccount?.accountType
          : data?.debitAccount?.accountType,
    };

    if (config.action === 'CREATE') {
      onCreate({
        body,
        url: `/cash/bank-note-types`,
        token: config.token,
        method: 'POST',
        noAlert: true,
        doAfterSuccess: res => {
          customSwaltAlert({
            icon: 'success',
            title: 'Creado exitosamente',
            text: res.message,
            showCancelButton: false,
          }).finally(() => {
            setData({});
            handleClose();
            onUpdateSuccess();
          });
        },
        doAfterException: res => {
          customSwaltAlert({
            icon: 'warning',
            title: 'Intenta de nuevo',
            text: res.message,
            showCancelButton: false,
          });
        },
      });
    } else {
      onUpdate({
        body,
        url: `/cash/bank-note-types/${config.id}`,
        token: config.token,
        method: 'PUT',
        noAlert: true,
        doAfterSuccess: res => {
          customSwaltAlert({
            icon: 'success',
            title: 'Actualizado exitosamente',
            text: res.message,
            showCancelButton: false,
          }).finally(() => {
            setData({});
            handleClose();
            onUpdateSuccess();
          });
        },
        doAfterException: res => {
          customSwaltAlert({
            icon: 'warning',
            title: 'Intenta de nuevo',
            text: res.message,
            showCancelButton: false,
          });
        },
      });
    }
  };

  useEffect(() => {
    setBankNoteTypes(initialStateBankNoteTypes);
  }, [initialStateBankNoteTypes]);

  useEffect(() => {
    getListPuc({
      token: config.token,
      url: `/admin/puc/accounts`,
      objFilters: { eaccount: config.eaccount, check_info_bank:1 },
    }).then(res => {
      if (res.success) {
        const dataFormat = res.results.map(el => ({
          label: `${el.complete_account} - ${el.description}`,
          value: el.id,
          type: el.type,
          nature: el?.nature
        }));
        setListPucDebit(dataFormat?.filter(el => el?.nature === 'D'))
        setListPuc(dataFormat?.filter(el => el?.nature === 'C'));
      }
    });
  }, [config.eaccount, config.token, getListPuc]);

  useEffect(() => {
    if (config?.action !== 'CREATE' && config?.id) {
      getDetail({
        token: config.token,
        url: `/cash/bank-note-types/${config.id}`,
        objFilters: { eaccount: config.eaccount },
      }).then(res => {
        if (res?.success) setData(res?.data);
      });
    }
  }, [config.action, config.id, config.eaccount, config.token, getDetail]);

  useEffect(() => {
    if (config.action === 'CREATE') {
      setData([]);
    }
  }, [config.action]);

  return (
    <>
      {(loaderUpdate || loaderDetail || loaderListPuc || loaderCreate) && loader}

      <Modal
        centered
        show={config?.show}
        onHide={() => {
          handleClose();
          setData({});
        }}
      >
        <Modal.Header className={`${treasuryStyles.modalHeader} justify-content-center`}>
        <img
            className={treasuryStyles.close}
            src={close}
            alt='close'
            width={22}
            height={22}
            onClick={() => {
              setData({});
              handleClose();
            }}
          />
          <Modal.Title style={{ fontWeight: 'bolder' }}>{`${title} tipo de nota`}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='px-5'>
          <div className={treasuryStyles.gridModal}>
            <div>
              <div>
                Nombre <b className={treasuryStyles.asterisk}>*</b>
              </div>
              <input
                type='text'
                placeholder='Escribir...'
                value={data?.name || ''}
                disabled={config.action === 'DETAIL'}
                className={`${treasuryStyles.input} w-100`}
                onChange={({ target }) => {
                  setData(state => ({ ...state, name: target.value }));
                }}
              />
            </div>

            <div className='w-100'>
              <div>
                Tipo de nota <b className={treasuryStyles.asterisk}>*</b>
              </div>
              <Select
                styles={ordCustomSelect}
                className='text-secondary w-100'
                placeholder={'Seleccionar...'}
                noOptionsMessage={() => 'No hay datos'}
                value={data?.type?.name ? { label: data.type.value, value: data.type.name } : ''}
                isDisabled={config.action === 'DETAIL'}
                options={bankNoteTypes}
                onChange={({ value, label }) => {
                  setData(state => ({
                    ...state,
                    creditAccount: {},
                    debitAccount: {},
                    type: {
                      name: value,
                      value: label,
                    },
                  }));
                }}
              />
            </div>
          </div>

          <div className={treasuryStyles.gridModal}>
            {['debit', '', undefined].includes(data?.type?.name) && (
              <div>
                <div>
                  Cuenta débito <b className={treasuryStyles.asterisk}>*</b>
                </div>
                <Select
                  styles={ordCustomSelect}
                  className='text-secondary w-100'
                  placeholder={'Seleccionar...'}
                  noOptionsMessage={() => 'No hay datos'}
                  options={listPucDebit}
                  isDisabled={
                    config.action === 'DETAIL' ||
                    ['credit', '', undefined].includes(data?.type?.name)
                  }
                  value={
                    data?.debitAccount?.id
                      ? { label: data?.debitAccount?.description, value: data?.debitAccount?.id }
                      : ''
                  }
                  onChange={({ label, value, type }) => {
                    setData(state => ({
                      ...state,
                      creditAccount: {},
                      debitAccount: {
                        id: value,
                        accountType: type,
                        description: label,
                      },
                    }));
                  }}
                />
              </div>
            )}

            {['credit', '', undefined].includes(data?.type?.name) && (
              <div className='w-100'>
                <div>
                  Cuenta crédito <b className={treasuryStyles.asterisk}>*</b>
                </div>
                <Select
                  styles={ordCustomSelect}
                  className='text-secondary w-100'
                  placeholder={'Seleccionar...'}
                  noOptionsMessage={() => 'No hay datos'}
                  isDisabled={
                    config.action === 'DETAIL' ||
                    ['debit', '', undefined].includes(data?.type?.name)
                  }
                  options={listPuc}
                  value={
                    data?.creditAccount?.id
                      ? {
                          label: data?.creditAccount?.description,
                          value: data?.creditAccount?.id,
                        }
                      : ''
                  }
                  onChange={({ label, value, type }) => {
                    setData(state => ({
                      ...state,
                      debitAccount: {},
                      creditAccount: {
                        id: value,
                        accountType: type,
                        description: label,
                      },
                    }));
                  }}
                />
              </div>
            )}
          </div>

          {['UPDATE', 'DETAIL'].includes(config?.action) ? (
            <div className={treasuryStyles.gridModalBlock}>
              <div>
                <div>Último cambio</div>
                <input
                  disabled
                  type='text'
                  className={`${treasuryStyles.input} w-100`}
                  value={`${data?.updatedBy?.firstName} ${data?.updatedBy?.firstSurname} ${data?.lastUpdated}`}
                />
              </div>
            </div>
          ) : (
            ''
          )}
        </Modal.Body>

        <Modal.Footer className='px-5 py-4' style={{ borderTop: '0px' }}>
          {config?.action !== 'DETAIL' && (
            <>
              <Button
                className={treasuryStyles.btnCancel}
                onClick={() => {
                  setData({});
                  handleClose();
                }}
              >
                Cancelar
              </Button>
              <Button className={treasuryStyles.btnPrimary} onClick={handleUpdate}>
                Guardar
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
});

BankNoteTypesConfig.displayName = 'BankNoteTypesConfig';
